import React from "react";
/** @jsx jsx */ 
import { jsx } from '@emotion/core';
import { css } from '@emotion/core';
import { MAIN_TEXT_COLOR, BUTTON_TEXT_COLOR, PRIMARY_COLOR } from "../styles";
import { Box } from "@material-ui/core";

export type LogoType = 'white' | 'colorful';

export interface LogoProps {
    type: LogoType;
}

const headingCSS = (type: LogoType) => css`
    color: ${type === 'white' ? BUTTON_TEXT_COLOR : MAIN_TEXT_COLOR};
    font-weight: bold;
    font-size: 28px;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
`;

const logoCSS = css`
    display: flex;
    justify-content: center;
`;

export function Logo({ type }: LogoProps) {
    return <Box css={logoCSS}>
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 15.5094V38L38 31.4981V9L22 15.5094Z" fill={type === 'white' ? 'white' : PRIMARY_COLOR}/>
            <path d="M0 31.4981L16 38V15.5094L0 9V31.4981Z" fill={type === 'white' ? 'white' : PRIMARY_COLOR}/>
            <path d="M19 0L4 5.61755L19 12L34 5.61755L19 0Z" fill={type === 'white' ? 'white' : PRIMARY_COLOR}/>
        </svg>
        <span css={headingCSS(type)}>
            Cloud Storage
        </span>
    </Box>
}