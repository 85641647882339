import React, { useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle, Tab, Tabs } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

interface HelpModalProps {
    open: boolean;
    onClose: () => void;
}

function getOS() {
    // @ts-ignore
    const platform = window.navigator?.userAgentData?.platform ?? window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];

    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else {
        os = 'Windows';
    }

    return os;
}


export function HelpModal({ open, onClose }: HelpModalProps) {
    const [value, setValue] = useState(getOS() === "Windows" ? 0 : 1);

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Installation instructions for the media viewer version 2</DialogTitle>
            <DialogContent style={{ overflowX: "hidden" }}>
                <Box>
                    In order to preview the files, please install the “VLC Media Player” and the “Sclas VLC Adapter”.
                    <br />
                    <br />
                    If you already have “Sclas VLC Adapter” on your computer and
                    File preview functionality is not working, please update it to the newest one following instruction below.
                </Box>

                <Box
                    display="flex"
                    justifyContent="center"
                    marginTop={4}
                >
                    <Tabs
                        value={value}
                        onChange={(e, value) => setValue(value)}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Windows" />
                        <Tab label="MacOS" />
                    </Tabs>
                </Box>
                <Box>
                    <div hidden={value !== 0}>
                        <div>
                            <h3><b>VLC Media Player</b> </h3>
                            <i>(This step can be omitted if the VLC Media Player is already installed on your PC)</i>
                            <ol>
                                <li> Download the VLC Media Player installer from the official website - <a href="https://www.videolan.org/vlc/download-windows.html" target="blank"> Download </a> </li>
                                <li> Run the installer and follow the instructions. </li>
                            </ol>
                        </div>

                        <div>
                            <h3><b>Sclas VLC Adapter </b></h3>
                            <ol>
                                <li> Download the Sclas VLC Adapter installer - <a href="/private-assets/SclasVLCAdapterInstaller.exe"> Download </a> </li>
                                <li> Run the downloaded <b>SclasVLCAdapterInstaller.exe</b> file and approve the Administrator permissions request. </li>
                                <li>
                                    Such message should appear as a result of successful installation (format could be different due to system settings) -
                                    <img style={{ position: "relative", left: "-65px", width: "600px" }} src="/private-assets/demo.png" />
                                </li>
                            </ol>
                        </div>

                        <Alert severity="success" style={{ position: "relative", top: "-10px", fontWeight: "bold" }}>You are ready to use media player!</Alert>
                    </div>

                    <div hidden={value !== 1}>
                        <div>
                            <h3><b>VLC Media Player</b> </h3>
                            <i>(This step can be omitted if the VLC Media Player is already installed on your Mac)</i>
                            <ol>
                                <li> Download the VLC Media Player installer from the official website - <a href="https://www.videolan.org/vlc/download-macosx.html" target="blank"> Download </a> </li>
                                <li> Open the installer and follow the instructions. </li>
                            </ol>
                        </div>

                        <div>
                            <h3><b>Sclas VLC Adapter </b></h3>
                            <ol>
                                <li> Download the Sclas VLC Adapter - <a href="/private-assets/SclasVLCAdapter.dmg"> Download </a> </li>
                                <li> Open the downloaded <b>SclasVLCAdapter.dmg</b> file and follow the instruction. </li>
                            </ol>
                        </div>


                        <Alert severity="success" style={{ position: "relative", fontWeight: "bold" }}>You are ready to use media player!</Alert>
                    </div>
                </Box>

                <br />
                <br />
            </DialogContent>
        </Dialog >
    )
}