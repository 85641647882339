import React, { useState } from 'react';
import { FileEntry } from '../organism/file.table';
import { TableRow, TableCell } from '@material-ui/core';
import { OrangeCheckbox } from '../atom/orange.checkbox';
import prettyBytes from 'pretty-bytes';
import { FileIcon } from '../atom/file.icon';
import { css } from '@emotion/core';
import { FileActions, FolderActions } from './file.actions';
import dayjs from 'dayjs';
import { Folder } from '../organism/file.table.content';

type FileTableRowProps = FileTableRowSizedProps & { windowWidth: number };

interface FileTableRowSizedProps {
    isSelected: Function;
    index: number;
    row: FileEntry;
    handleClick: Function;
    files: FileEntry[];
    setFiles: Function;
    updateFileList: Function;
    matterNumber: string;
}

type FolderTableRowProps = FolderTableRowSizedProps & { windowWidth: number };

interface FolderTableRowSizedProps {
    isSelected: Function;
    index: number;
    folderName: string;
    handleClick: Function;
    openDirectory: () => void;
    path: string[];
    folders: Folder;
    setFolders: (folders: Folder) => void;
    disabledDelete: boolean;
    matterNumber: string;
}

const fileNameCellCSS = css`
    display: flex;
    align-items: center;
`;

const additionalInfoCSS = css`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
`;

const fileInfoContainerCSS = css`
    display: flex;
`;

const fileCellCSS = css`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const rowSizingCSS = css`
    td:nth-of-type(1) {
        width: 70% !important;
    }
    
    td:nth-of-type(2) {
        width: 30% !important;
    }
`;

export const rowSizingCSS2 = css`
    th:nth-of-type(1) {
        width: 80% !important;
    }
`;


function FileTableRowSmall({ row, files, setFiles, updateFileList, matterNumber }: FileTableRowSizedProps) {
    const [processing, setProcessing] = useState<boolean>(false);

    return <TableRow css={rowSizingCSS}>

        <TableCell>
            <FileActions onlyMainShow fileType={row.fileType} path={["pep"]} folders={{}} setFolders={() => { }} matterNumber={matterNumber} canAddFolders={false} processing={processing} setProcessing={setProcessing} files={files} setFiles={setFiles} fileName={row.fileName} updateFileList={updateFileList} />
        </TableCell>
        <TableCell>
            <div css={fileInfoContainerCSS}>
                <FileIcon type={row.fileType} />
                <div css={fileCellCSS}>
                    <div>
                        {row.fileName.split("/").pop()}
                    </div>
                    <div css={additionalInfoCSS}>
                        <span>{row.fileType}</span>
                        <span>{dayjs(row.uploadDate).format('MMM D, YYYY')}</span>
                        <span>{prettyBytes(row.size)}</span>
                    </div>
                </div>
            </div>
        </TableCell>
        <TableCell>
            <FileActions fileType={row.fileType} path={["pep"]} folders={{}} setFolders={() => { }} matterNumber={matterNumber} canAddFolders={false} processing={processing} setProcessing={setProcessing} files={files} setFiles={setFiles} fileName={row.fileName} updateFileList={updateFileList} />
        </TableCell>
    </TableRow>
}

function FileTableRowBig({ isSelected, index, row, handleClick, files, setFiles, updateFileList, matterNumber }: FileTableRowSizedProps) {
    const isItemSelected = isSelected(row.fileName);
    const labelId = `table-checkbox-${index}`;

    const [processing, setProcessing] = useState<boolean>(false);


    return <TableRow
        hover
        role='checkbox'
        aria-checked={isItemSelected}
        tabIndex={-1}
        css={rowSizingCSS2}
        key={row.fileName}
    >
        <TableCell padding="checkbox">
            <OrangeCheckbox
                onClick={event => handleClick(event, row.fileName)}
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
                disabled={processing}
            />
        </TableCell>
        <TableCell align='left'>
            <FileActions onlyMainShow fileType={row.fileType} path={["pep"]} folders={{}} setFolders={() => { }} matterNumber={matterNumber} canAddFolders={false} processing={processing} setProcessing={setProcessing} files={files} setFiles={setFiles} fileName={row.fileName} updateFileList={updateFileList} />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
            <div css={fileNameCellCSS}>
                <FileIcon type={row.fileType} />
                {row.fileName.split("/").pop()}
            </div>

        </TableCell>
        <TableCell align="left">{row.fileType}</TableCell>
        <TableCell align="left">{dayjs(row.uploadDate).format('MMM D, YYYY')}</TableCell>
        <TableCell align="left" colSpan={2}>{prettyBytes(row.size)}</TableCell>
        <TableCell align='left'>
            <FileActions fileType={row.fileType} path={["pep"]} folders={{}} setFolders={() => { }} matterNumber={matterNumber} canAddFolders={false} processing={processing} setProcessing={setProcessing} files={files} setFiles={setFiles} fileName={row.fileName} updateFileList={updateFileList} />
        </TableCell>
    </TableRow>
}

function FolderTableRowSmall({ folderName, openDirectory }: FolderTableRowSizedProps) {

    return <TableRow css={rowSizingCSS}>
        <TableCell colSpan={3}>
            <div css={fileInfoContainerCSS} onClick={openDirectory}>
                <FileIcon type={"folder"} />
                <div css={fileCellCSS}>
                    <div>
                        {folderName}
                    </div>
                    <div css={additionalInfoCSS}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </TableCell>
    </TableRow>
}

function FolderTableRowBig({ isSelected, index, folderName, handleClick, openDirectory, ...props }: FolderTableRowSizedProps) {
    const isItemSelected = isSelected(folderName);
    const labelId = `table-checkbox-${index}`;

    return <TableRow
        hover
        role='checkbox'
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={folderName}
    >
        {<TableCell padding="checkbox">
        </TableCell>}
        <TableCell colSpan={2} onClick={openDirectory} component="th" id={labelId} scope="row" padding="checkbox">
            <div css={fileNameCellCSS}>
                <FileIcon type={"folder"} />
                {folderName}
            </div>
        </TableCell>
        <TableCell align="left">{ }</TableCell>
        <TableCell align="left">{ }</TableCell>
        <TableCell align="left">{ }</TableCell>
        <TableCell align="left">{ }</TableCell>
        <TableCell align='left'>
            <FolderActions {...props} folderName={folderName} />
        </TableCell>

    </TableRow>
}

export function FolderTableRow({ windowWidth, ...props }: FolderTableRowProps) {
    if (windowWidth > 830) {
        return <FolderTableRowBig {...props} />
    } else {
        return <FolderTableRowSmall {...props} />
    }
}

export function FileTableRow({ windowWidth, ...props }: FileTableRowProps) {

    if (windowWidth > 830) {
        return <FileTableRowBig {...props} />
    } else {
        return <FileTableRowSmall {...props} />
    }
}