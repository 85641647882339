import React, { useContext } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    RouteProps,
    Redirect,
} from "react-router-dom";
import { LoginPage } from './loginPage';
import { MainPage } from './mainPage';
import { LoginLayout } from '../layout/login.layout';
import { MainPageLayout } from '../layout/main.page.layout';
import { globalContext } from "../store/global";
import { MainHeader } from '../organism/main.header';
import dayjs from 'dayjs';
import { MatterPage } from './matter.page';




interface ProtectedRouteProps {
    element: JSX.Element;

    notAuth?: boolean;
}

function ProtectedRoute({ element, notAuth, ...restProps }: RouteProps & ProtectedRouteProps) {
    // Use global context
    const appContext = useContext(globalContext);

    return <Route {...restProps} render={() => {
        if (notAuth === true)
            return appContext.state.isSigned === true ? element : element;
        if (!appContext.state.isSigned) {
            return <Redirect to="/login" />;
        }
        return  element ;
    }} />;
}

export function App() {
    const appContext = useContext(globalContext);
    setInterval(() => {
        let sessionExpiration = sessionStorage.getItem('sessionExpiration'); 
        if (sessionExpiration) {
            if (dayjs() > dayjs(sessionExpiration)) {
                appContext.actions.signOut();
                sessionStorage.clear();
            }
        }
        
    }, 1000 * 30);

    return<Router>
            <Switch>
                <ProtectedRoute notAuth path="/login/:caseNumber" element={<LoginLayout>
                    <LoginPage />
                </LoginLayout>}>
                </ProtectedRoute>

                <ProtectedRoute path="/" exact element={<MainPageLayout header={<MainHeader />} content={<MainPage />} />} />
                <ProtectedRoute path="/matters" exact element={<MainPageLayout header={<MainHeader />} content={<MatterPage />} />} />
                <ProtectedRoute notAuth path="/login" element={<LoginLayout>
                    <LoginPage />
                </LoginLayout>}>
                </ProtectedRoute>

                <ProtectedRoute notAuth path="/:caseNumber" exact element={<LoginLayout>
                    <LoginPage />
                </LoginLayout>} />
            <Redirect to='/login' />
            </Switch>
        </Router>;
}