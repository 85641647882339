import { createAction } from "redux-actions";
import { Relation } from "../../organism/login.form";

export const SignInAction = createAction("SIGN_IN", () => true);

export const SignOutAction = createAction("SIGN_OUT", () => false);

export const SetAvailableMatter = createAction("SETAVAILABLEMATTERS", (matters: Relation[]) => matters);

export const SetCaseNumberAction = createAction('SET_CASE_NUMBER', (caseNumber: any) => caseNumber);

export const SetShowDeletedAction = createAction('SET_SHOW_DELETED', (showDeleted: boolean) => showDeleted);

export const SwitchExplainers = createAction("SWITCH_EXPLAINER", (isEnabled: boolean) => isEnabled);

export enum UserRole {
    User,
    Admin,
    SuperAdmin
}

export interface UserPayload {
    id: string;
    userName: string;
    password: string;
    name: string;
    role: UserRole;
}

export const AssignUserAction = createAction("ASSIGN_USER", (payload: UserPayload) => payload);
