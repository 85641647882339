import React, { useEffect, useState, useContext } from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { css } from '@emotion/core';
import { Box, Grid, Paper, Table, TablePagination } from '@material-ui/core';
import { FileTableHeader, useStyles, Order } from '../molecule/file.table.header';
import { FileTableContent, Folder } from './file.table.content';
import { MAIN_FONT_FAMILY } from '../styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableToolBar } from '../molecule/table.toolbar';
import axios from 'axios';
import { globalContext, GlobalContext } from '../store/global';
import { FileTabFolders } from './folders.bar';
import { getFilesFilteredByFolder } from '../utils/file.filters';

export interface FileEntry {
    case: string;
    fileName: string;
    fileType: string;
    uploadDate: string;
    size: number;
}

const fileTableCSS = css`
    display: flex;
    justify-content: center;
`;

const tableCSS = css`
    tbody:before {
        content:"@";
        display:block;
        line-height:10px;
        text-indent: -100%;
    }

    font-family: ${MAIN_FONT_FAMILY} !important;
    .MuiTableCell-root {
        font-family: inherit !important;
        font-weight: inherit !important;
    }
    min-width: unset !important;
`;


interface ResponseFile {
    matter: string;
    fileName: string;
    size: number;
    lastModified: string;
}

function handleRequestSort(setOrder: any, setOrderBy: any, property: keyof FileEntry, orderBy: keyof FileEntry, order: Order) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
}

function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>, setSelected: any, files: FileEntry[], path: string[]) {
    if (event.target.checked) {
        const newSelecteds = getFilesFilteredByFolder(files, path).map(n => n.fileName);
        setSelected(newSelecteds);
        return;
    }
    setSelected([]);
}

function handleClick(name: string, selected: string[], setSelected: any) {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
    }

    setSelected(newSelected);
}

function handleChangePage(newPage: number, setPage: any) {
    setPage(newPage);
}

function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>, setRowsPerPage: React.Dispatch<React.SetStateAction<number>>,
    setPage: React.Dispatch<React.SetStateAction<number>>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
}

async function getFiles(global: GlobalContext, caseName: string | undefined, setNonFilteredFiles: React.Dispatch<React.SetStateAction<FileEntry[]>>,
    convertDataToFileEntries: Function, setFiles: React.Dispatch<React.SetStateAction<FileEntry[]>>) {
    axios.get(`/access/${caseName}`).then(res => {
        axios.get(`/file/${caseName}`).then(res => {
            let data = res.data;
            //filter files with deleted names
            setNonFilteredFiles(convertDataToFileEntries(data));
            setFiles(convertDataToFileEntries(data).filter((fileEntry: FileEntry) => !fileEntry.fileName.split("/").pop()?.startsWith('Deleted-')));
        }).catch(err => {
            global.actions.signOut();
        });
    }).catch(err => {
        global.actions.signOut();
    });
}

function convertDataToFileEntries(data: ResponseFile[]) {
    const fileEntries: FileEntry[] = data.map(dataFile => {
        let fileNameSplitted = dataFile.fileName.split('.');
        let fileType = fileNameSplitted[fileNameSplitted.length - 1];

        return {
            case: dataFile.matter,
            fileName: dataFile.fileName,
            fileType: fileType,
            size: dataFile.size,
            uploadDate: dataFile.lastModified
        };
    });
    return fileEntries;
}

async function updateFileList(global: GlobalContext, caseName: string | undefined, setNonFilteredFiles: React.Dispatch<React.SetStateAction<FileEntry[]>>,
    convertDataToFileEntries: Function, setFiles: React.Dispatch<React.SetStateAction<FileEntry[]>>) {
    await getFiles(global, caseName, setNonFilteredFiles, convertDataToFileEntries, setFiles);
}

export function FileTable({ caseNumber }: { caseNumber: string }) {
    const global = useContext(globalContext);
    const [files, setFiles] = useState<FileEntry[]>([]);
    const [nonFilteredFiles, setNonFilteredFiles] = useState<FileEntry[]>([]);
    const [isLoading, setLoading] = useState(true);
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof FileEntry>('fileName');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [caseName, setCaseName] = useState<string>('');
    const [windowWidth, setWindowWidth] = useState<number>(1920);
    const [foldersOpen, setFoldersOpen] = useState<Array<string>>([]);

    const [folders, setFolders] = useState<Folder>({});
    const [currentFolders, setCurrentFolders] = useState<Folder>({});

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, files.length - page * rowsPerPage);

    const openDirectory = (name: string) => {
        setPage(0);
        setFoldersOpen([...foldersOpen, name]);
        setCurrentFolders(currentFolders[name]);
    }

    //Loading Case Files information
    useEffect(() => {
        async function loadInfo() {
            setCaseName(`${caseNumber}`);
            await getFiles(global, caseNumber, setNonFilteredFiles, convertDataToFileEntries, setFiles);

            const { data, status } = await axios.get(`/folder/${caseNumber}`);

            try {
                if (data && data.startsWith("<!doctype html>"))
                    global.actions.signOut();
            } catch (ex) {

            }

            if (status === 200) {
                setFolders(data);
                setCurrentFolders(data);
            }

            setLoading(false);
        }

        loadInfo();

        setWindowWidth(window.innerWidth);
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        });

        return () => {
            window.removeEventListener('resize', () => { });
        }

    }, [global]);

    useEffect(() => {
        let addedInFolders = foldersOpen.reduce((p, c) => {
            if (p[c] === null) {
                p[c] = {}
            }

            return p[c]
        }, folders);

        setCurrentFolders(addedInFolders);
    }, [folders]);

    function movePath(index: number) {
        const newPath = index ? foldersOpen.slice(0, index) : [];

        let changedFolders = newPath.reduce((p, c) => {
            if (p[c] === null) {
                p[c] = {}
            }

            return p[c]
        }, folders);

        setCurrentFolders({ ...changedFolders });
        setFoldersOpen(newPath);
    }

    return <Box css={fileTableCSS}>
        <Grid item xs={12} md={10} >
            {isLoading ? <Skeleton variant="rect" height={600} /> : <Paper className={classes.paper}>
                <TableToolBar matter={global.state.matters.find(m => m.matterNumber === caseNumber) ?? { matterNumber: caseNumber ?? "0", docketNumber: "", indictment: "", defendentsName: "" }}
                    folders={folders} setFolders={(folders: Folder) => setFolders(folders)}
                    windowWidth={windowWidth}
                    path={foldersOpen}
                    filesNum={global.state.showDeleted ? nonFilteredFiles.length : files.length}
                    selectedFiles={selected} files={global.state.showDeleted ? nonFilteredFiles : files}
                    setFiles={global.state.showDeleted ? setNonFilteredFiles : setFiles}
                    updateFileList={() => updateFileList(global, caseName, setNonFilteredFiles, convertDataToFileEntries, setFiles)}
                    caseNumber={caseNumber}
                />
                <div className={classes.tableWrapper}>
                    <FileTabFolders movePath={movePath} path={foldersOpen} />

                    <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        size='medium'
                        aria-label='Files Table'
                        css={tableCSS}
                    >
                        <FileTableHeader
                            windowWidth={windowWidth}
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={(event) => handleSelectAllClick(event, setSelected, files, foldersOpen)}
                            onRequestSort={(event, property) => handleRequestSort(setOrder, setOrderBy, property, orderBy, order)}
                            rowCount={global.state.showDeleted ? nonFilteredFiles.length : files.length}
                        />

                        <FileTableContent
                            windowWidth={windowWidth}
                            files={global.state.showDeleted ? nonFilteredFiles : files}
                            order={order}
                            orderBy={orderBy}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            isSelected={isSelected}
                            handleClick={(event: any, name: string) => handleClick(name, selected, setSelected)}
                            emptyRows={emptyRows}
                            setFiles={setFiles}
                            allFolders={folders}
                            updateFileList={() => updateFileList(global, caseName, setNonFilteredFiles, convertDataToFileEntries, setFiles)}
                            folders={currentFolders || {}}
                            openDirectory={openDirectory}
                            path={foldersOpen}
                            setFolders={setFolders}
                            matterNumber={caseNumber}
                        />
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component='div'
                    count={global.state.showDeleted ? getFilesFilteredByFolder(nonFilteredFiles, foldersOpen).length : getFilesFilteredByFolder(files, foldersOpen).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event: unknown, newPage) => handleChangePage(newPage, setPage)}
                    onChangeRowsPerPage={(event: any) => handleChangeRowsPerPage(event, setRowsPerPage, setPage)}
                />
            </Paper>}

        </Grid>

    </Box>;
}