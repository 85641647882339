import React from "react";
import { css } from "@emotion/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

interface FoldersProps {
    path: string[];
    movePath: (index: number) => void
}

const HeaderPathEntry = css`
    margin-left: 5px;
    > span {
        font-weight: 600;
        cursor: pointer;

        :hover {
            text-decoration: underline;
            color: #A42351;
        }
    }
`;

const tableHeadCSS = css`
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #F1F1F5;
    border-bottom: 1px solid #c5c5c5;

    > div {
        margin-right: 20px;
    }
`;

export const backFolder = css`
    height: 17px;
    float: right;
    > svg {
        height: 17px !important; 
    }
`;

const pathCSS = css`
    display: flex;
`;

export const moveBackCSS = css`
    cursor: pointer;
    border: 1px solid black;
    border-radius: 6px;
`;

export function FileTabFolders({ path, movePath }: FoldersProps) {

    return <div css={tableHeadCSS}>
        <div css={moveBackCSS} onClick={() => movePath(path.length - 1)}>
            <KeyboardBackspaceIcon css={backFolder} />
        </div>

        <div css={pathCSS}>
            <div css={HeaderPathEntry}> / <span onClick={() => movePath(0)}> ROOT </span> / </div>
            {path.map((value, index) => <div key={index + value} css={HeaderPathEntry}> <span onClick={() => movePath(index + 1)}>{value}</span> / </div>)}
        </div>

    </div>
}