export function searchForKeyEntry(ob : Object, name: string, path: string[]) {
    const pathToFind: string[] = [];

    const res = findKey(ob, name, path, pathToFind);

    return res;
}

function findKey(obj: any, name: string, path: string[], pathToFind: string[]): boolean | string {
    let founded;

    if(obj) {
        Object.keys(obj).forEach(key => {
            if (key.substring(0,8) == 'Deleted-' && key.substring(key.length - name.length) == name && JSON.stringify(path) == JSON.stringify(pathToFind)) {
                founded = key;
            }
        });
    }
    
    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
        return false;
    }
    else if (founded) {
        return founded;
    }
    else {
        for (const k in obj) {
            pathToFind.push(k);
            const result = findKey(obj[k], name, path, pathToFind);
            if (result) {
                return result;
            }
            pathToFind.pop();
        }
    }
    return false;
}