import { Relation } from "../../organism/login.form";
import { createReducer } from "../../utils/reducer.builder";
import { AssignUserAction, SignInAction, SignOutAction, SwitchExplainers, UserPayload, SetCaseNumberAction, SetShowDeletedAction, SetAvailableMatter } from "./actions";

export interface GlobalState {
    isSigned?: boolean;
    user?: UserPayload;
    caseNumber?: string;
    showDeleted?: boolean;
    matters: Relation[];
}

/** Init reducer state */
const globalReducer = createReducer<GlobalState>();

/** Handle state changes. New state object is already created. */
globalReducer.on(SignInAction, (state) => state.isSigned = true);
globalReducer.on(SignOutAction, (state) => state.isSigned = false);
globalReducer.on(AssignUserAction, (state, payload) => state.user = payload);
globalReducer.on(SetCaseNumberAction, (state, caseNumber) => state.caseNumber = caseNumber);
globalReducer.on(SetShowDeletedAction, (state, showDeleted) => state.showDeleted = showDeleted);
globalReducer.on(SetAvailableMatter, (state, matters) => state.matters = matters);

/** Export function that can be passed to useReducer */
export default globalReducer.build();
