import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import { Button } from '../atom/button';

interface FileSameNameDialogProps {
    handleCancel: Function;
    handleRewrite: () => void;
    open: boolean;
}

export function FileSameNameDialog({ handleCancel, handleRewrite, open }: FileSameNameDialogProps) {


    return <Dialog
        open={open}
        onClose={handleRewrite}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
    >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
            <DialogContentText id='alert-dialog-description'>
                You are loading files, which has same name as some files on server. Do you want to overwrite them?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancel} intent='danger'>
                No
            </Button>
            <Button onClick={handleRewrite} intent='primary' autoFocus >
                Yes
            </Button>
        </DialogActions>
    </Dialog>
}