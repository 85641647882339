import React, { useContext } from "react";
import { FileTable } from "../organism/file.table";
import { Box } from "@material-ui/core";
import { globalContext } from "../store/global";

interface MainPageProps {
    setOpenModal: Function;
}

export function MainPage() {
    const global = useContext(globalContext);

    return <>{global.state.matters.map(matter => <Box key={matter.matterNumber}>
        <FileTable caseNumber={matter.matterNumber} />
    </Box>)
    }</>;
}

