import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { Logo } from '../molecule/logo';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { css } from '@emotion/core';
import { UserProfile } from '../molecule/user.profile';

const headerCSS = css`
    padding: 10px 20px;
`;

export function MainHeader() {
    return <Paper css={headerCSS}>
        <Grid container>
            <Grid item xs={4}>
                <Logo type='colorful' />
            </Grid>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={2}>
                <UserProfile />
            </Grid>
        </Grid>
    </Paper>;
}