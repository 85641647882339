import React from 'react';
import { icons } from '../file.icons';
import FILE from '../file.icons/file.svg';
/** @jsx jsx */ 
import { jsx } from '@emotion/core';
import { css } from '@emotion/core';

interface FileIconProps {
    type: string;
}

const iconCSS = css`
    width: 38px;
    height: 42px;
    margin-right: 6px;
`;

export function FileIcon({ type }: FileIconProps) {
    const iconPath = Object.keys(icons).includes(type.toLowerCase()) ? icons[type.toLowerCase()] : FILE;

    return <img src={iconPath} css={iconCSS} />
}