import { css } from "@emotion/core";
import { CircularProgress } from "@material-ui/core";
import { SnackbarMessage } from "notistack";
import React, { forwardRef } from "react";


const containerCSS = css`
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
    max-width: 450px;

    font-size: 0.875rem;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border: 2px solid #ff9800;
    overflow: hidden;

    > div {
        padding: 14px 16px;
    }
`;

const titleContainerCSS = css`
    background: #ff9800;
    display: flex;
    align-items: center; 
    color: white;
    font-weight: 500;
    font-size: 15px;
`;

const titleCSS = css`
    margin-left: 14px;
`;

const detailsContainerCSS = css`
    background: #f5f5f5;
    color: black;
`;

interface SnackbarTranscodingContentProps {
    message: SnackbarMessage;
    filename: string;
}

export const SnackbarTranscodingContent = forwardRef<HTMLDivElement, SnackbarTranscodingContentProps>(({ message, filename }, ref) => {

    return <div ref={ref} css={containerCSS}>
        <div css={titleContainerCSS}>
            <CircularProgress size={24} style={{ color: "white" }} />
            <div css={titleCSS}>File is not ready for the preview. Transcoding...</div>
        </div>
        <div css={detailsContainerCSS}>
            This process can take up to several minutes depending on the file size and document complexity.
            Average estimated time is <b> 30 sec. </b>
            <br /> <br />
            <b> {filename} </b> will be immediately opened for preview once it is transcoded.
        </div>
    </div>;
});