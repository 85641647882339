import React from "react";
import { Box } from "@material-ui/core";
import { MatterTable } from "../organism/matter.table";

export function MatterPage() {
    return <Box>
        <MatterTable />
    </Box>;
}

