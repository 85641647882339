import { Schema, ValidationError } from "yup";

export function getFormValidation(schema: Schema<any>) {
    return (values: any) => {
        try {
            schema.validateSync(values, { abortEarly: false });
        } catch (err) {
            return (err as ValidationError).inner.reduce((formError, innerError) => ({ ...formError, [innerError.path]: innerError.message }), {});
        }
    };
}