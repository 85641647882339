import { FormGroup, HTMLInputProps, IFormGroupProps, IInputGroupProps, InputGroup } from "@blueprintjs/core";
import { css } from "@emotion/core";
import React from "react";
import { FieldRenderProps } from "react-final-form";

export type AdvancedInputProps = IInputGroupProps & { name?: string, error?: boolean, rightIcon?: JSX.Element } & HTMLInputProps;

type AdvancedInputGroupProps = FieldRenderProps<any, any> & AdvancedInputProps & { groupProps?: IFormGroupProps };

const advancedInputCSS = css`
    margin:  0 15px;

    .bp3-input {
        height: 38px;
        line-height: 35px;
        background: #fafafb;
        font-family: 'Roboto', sans-serif;
        transition: .5s ease background-color, .5s ease box-shadow;
        font-size: 14px;
        border-radius: 15px;
        box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 0px 1px rgba(16, 22, 26, 0.2);
    }

    .bp3-input:disabled {
        background: rgba(179, 169, 169, 0.25);
    }

    .bp3-input:focus {
        box-shadow: 0 0 0 1px #4880ff, 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.01);
    }
`;

const errorInputCSS = css`
    .bp3-input {
        box-shadow:
            inset 0 0 0 1px rgba(193, 0, 0, 0.73),
            inset 0 1px 1px rgba(193, 0, 0, 0.753);
    }
`;

export const FormInput = ({ error, ...props }: AdvancedInputProps) => (
    <InputGroup
        css={[advancedInputCSS, error && errorInputCSS]}
        {...props}
    />
);

export function AdvancedInputGroup({ input, meta, type, placeholder, rightIcon }: AdvancedInputGroupProps) {
    const error = meta.error || "";

    return <FormGroup>
        <FormInput
            {...input}
            type={type}
            placeholder={placeholder}
            error={error.length > 0 && meta.touched}
            disabled={meta.submitting}
            rightElement={rightIcon && rightIcon}
        />
    </FormGroup>
}