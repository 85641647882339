import { withStyles, CircularProgress } from '@material-ui/core';
import { PRIMARY_COLOR } from '../styles';

export const WhiteCircularProgress = withStyles({
    root: {
        color: '#fff',
    }
})(CircularProgress);

export const MainCircularProgress = withStyles({
    root: {
        color: PRIMARY_COLOR
    }
})(CircularProgress);