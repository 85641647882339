import React, { useContext, Dispatch, SetStateAction } from 'react';
import { Avatar, Box, Menu, MenuItem, Button } from '@material-ui/core';
import { globalContext, GlobalContext } from '../store/global';
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { SECONDARY_COLOR } from '../styles';
import axios from 'axios';

const userProfileCSS = css`
    display: flex;
`;

const avatarCSS = css`
    margin-right: 10px;
    background-color: ${SECONDARY_COLOR} !important;
`;

function handleClose(setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>) {
    setAnchorEl(null);
}

async function handleLogout(global: GlobalContext) {
    const { status, data } = await axios.get('/auth/signout');

    if (status === 200) {
        global.actions.setCaseNumber('');
        global.actions.signOut();
        sessionStorage.clear();
    } else {
        console.log('err');
    }
}

function handleShowDeleted(setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>, global: GlobalContext) {
    handleClose(setAnchorEl);
    global.actions.setShowDeleted(true);
}

function handleHideDeleted(setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>, global: GlobalContext) {
    handleClose(setAnchorEl);
    global.actions.setShowDeleted(false);
}

export function UserProfile() {
    const global = useContext(globalContext);
    const username = global.state.user ? global.state.user.name : 'User';
    let avatarName: string = '';
    username.split(' ').forEach(word => {
        avatarName += word.slice(0, 1).toUpperCase();
    });

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    return <Box css={userProfileCSS}>

        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => setAnchorEl(event.currentTarget)} endIcon={<ArrowDropDownIcon />}>
            <Avatar css={avatarCSS}>
                {avatarName}
            </Avatar>
            {username}
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => handleClose(setAnchorEl)}
        >
            {global.state.user!.role.toString() === 'Admin' &&
                (global.state.showDeleted ?
                <MenuItem onClick={() => handleHideDeleted(setAnchorEl, global)}>Hide deleted</MenuItem> :
                <MenuItem onClick={() => handleShowDeleted(setAnchorEl, global)}>Show deleted</MenuItem>
                )
            }
            <MenuItem onClick={() => handleLogout(global)}>Logout</MenuItem>
        </Menu>
    </Box>
}