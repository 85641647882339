import React, { createContext, PropsWithChildren, useReducer, useEffect } from "react";
import { Relation } from "../../organism/login.form";
import { AssignUserAction, SignInAction, SignOutAction, UserPayload, SetCaseNumberAction, SetShowDeletedAction, SetAvailableMatter } from "./actions";
import globalReducer, { GlobalState } from "./reducer";

/** Initial state */
const globalInitialState: GlobalState = {
    isSigned: false,
    showDeleted: false, 
    matters: []
};

/** Wrapped actions */
function setActions(dispatch: React.Dispatch<any>) {
    // Create inline map
    return {
        signIn: () => dispatch(SignInAction),
        signOut: () => dispatch(SignOutAction),
        assignUser: (user: UserPayload) => dispatch(AssignUserAction(user)),
        setCaseNumber: (caseNumber: any) => dispatch(SetCaseNumberAction(caseNumber)),
        setShowDeleted: (showDeleted: boolean) => dispatch(SetShowDeletedAction(showDeleted)),
        setMatters: (matters: Relation[]) => dispatch(SetAvailableMatter(matters))
    };
}

export interface GlobalContext {
    state: typeof globalInitialState;
    actions: ReturnType<typeof setActions>;
}

/** Create global context for application */
export const globalContext = createContext(globalInitialState) as any as React.Context<GlobalContext>;

export function getGlobalProvider() {
    
    // Using curring for providing a component with internal scope
    return ({ children }: PropsWithChildren<{}>) => {
        const sessionState = JSON.parse(sessionStorage.getItem('globalState') || 'null');

        
        const [state, dispatch] = useReducer(globalReducer, !sessionState ? globalInitialState : sessionState);

        useEffect(() => {
            sessionStorage.setItem('globalState', JSON.stringify(state));
        }, [state]);

        // Init provider value
        const value = { state, actions: setActions(dispatch) };

        // Return global context provided with already typed value
        return <globalContext.Provider value={value}> {children} </globalContext.Provider>;
    };
}