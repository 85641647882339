
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import { App } from './page/App';
import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import { SnackbarProvider } from 'notistack';


import "@blueprintjs/core/lib/css/blueprint.css";
import { getGlobalProvider } from './store/global';

const GlobalProvider = getGlobalProvider();

ReactDOM.render(
    <GlobalProvider>
        <SnackbarProvider>
            <App />
        </SnackbarProvider>
    </GlobalProvider>,
    document.getElementById('root')
);

unregister();
