import React, { useState, useContext } from "react";
import { css } from "@emotion/core";
import { useField, useForm } from "react-final-form-hooks";
import { FormApi } from "final-form";
import { getFormValidation } from "../utils/validations";
import * as Yup from "yup";
import { FormError } from "../atom/form.error";
import { Button } from "../atom/button";
import { PRIMARY_COLOR, BUTTON_TEXT_COLOR, MAIN_FONT_FAMILY } from "../styles";
import { AdvancedInputGroup } from "../molecule/input.group";
import { FormHeader } from "../atom/form.header";
import axios from 'axios';
import { globalContext, GlobalContext } from "../store/global";
import { __RouterContext, useParams } from "react-router";
import { WhiteCircularProgress } from "../atom/circular.progress";
import { setSession } from "../molecule/archivate.dialog";

export const MainButtonCSS = css`
    background-color: ${PRIMARY_COLOR} !important;
    color: ${BUTTON_TEXT_COLOR} !important;
    background-image: none !important;
    border-radius: 10px !important;
    width: 120px;
    height: 38px;
    font-weight: bold !important;
    font-size: 12px;
    font-family: ${MAIN_FONT_FAMILY};
`;

export const formCSS = css`
    text-align: center;
    margin-top: 6px;

    > div a {
        color: #c3c3c3 !important;
        font-size: 12px;
        position: relative;
        top: -15px;
    }

    > button {
        margin-top: 15px;
    }
`;

interface LoginValues {
    username: string;
    password: string;
}

interface MatterRelation {
    child: Relation;
    id: string;
    parent: Relation
    relationType: string;
}

export interface Relation {
    matterNumber: string;
    defendentsName: string;
    docketNumber: string;
    indictment: string;
}

const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required!").min(1).max(50),
    password: Yup.string().required("Password is required!").min(1).max(50)
});

async function checkAccess(caseNumber: string | undefined) {
    try {
        const { status } = await axios.get(`/access/${caseNumber}`);
        if (status == 200) {
            return true;
        }
    } catch (err) {

    }

    return false;
}

function getSubmitHandler(context: GlobalContext, setError: React.Dispatch<React.SetStateAction<string | undefined>>, caseNumber: string | undefined, setFormSubmitting: Function) {
    return async ({ username, password }: LoginValues, form: FormApi<LoginValues>) => {
        setFormSubmitting(true);
        setError('');
        if (!caseNumber) {
            setError("Wrong Case Number");
            setFormSubmitting(false);
            return;
        }
        try {
            const res = await axios.post('/auth/signin', { userName: username, password });

            const access = await checkAccess(caseNumber);

            if (!access) {
                setError("You don't have access to this case or it has been closed");
                setFormSubmitting(false);
                return;
            }

            const data = await getRelated(context, caseNumber);

            setSession();
            context.actions.assignUser(res.data);
            context.actions.signIn();
            console.log(data);
            if (data.length <= 1) {
                context.actions.setCaseNumber(caseNumber);
                context.actions.setMatters(data);
                window.location.href = '/';
            } else {
                context.actions.setMatters(data);
                window.location.href = '/';
            }

        } catch (err) {
            setError("Wrong credentials");
            setFormSubmitting(false);
        }
    };
}

async function getRelated(context: GlobalContext, caseNumber: string) {
    try {
        const { data, status } = await axios.get(`/matter/${caseNumber}/info`);
        console.log(data);
        if (status !== 200) {
            return [];
        }

        const parents: Relation[] = data.childRelations.map((parentMatter: MatterRelation) => parentMatter.parent);

        const children: Relation[] = data.parentRelations.map((childMatter: MatterRelation) => childMatter.child);

        return [...parents, ...children, data.matterInfo];

    } catch (err) {
        return [];
    }
}

export function LoginForm() {
    const [error, setError] = useState<string>();
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const { caseNumber } = useParams();

    const global = useContext(globalContext);

    const { form, handleSubmit } = useForm<LoginValues>({
        onSubmit: getSubmitHandler(global, setError, caseNumber, setFormSubmitting),
        validate: getFormValidation(validationSchema)
    });

    const usernameField = useField("username", form);
    const passwordField = useField("password", form);

    return <form onSubmit={handleSubmit} css={formCSS}>
        <FormHeader text="Login to your account" />

        <FormError text={error} />

        <AdvancedInputGroup {...usernameField} placeholder="Your username" />
        <AdvancedInputGroup {...passwordField} placeholder="Your password" type="password" />

        <Button css={MainButtonCSS} intent="primary" type="submit" disabled={formSubmitting}>
            {formSubmitting ? <WhiteCircularProgress size={24} /> : 'Continue'}
        </Button>
    </form>
}