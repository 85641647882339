import React, { useContext } from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { css } from '@emotion/core';
import { TableBody } from '@material-ui/core';
import { FileEntry } from './file.table';
import { Order } from '../molecule/file.table.header';
import { FileTableRow, FolderTableRow } from '../molecule/file.table.row';
import { globalContext } from '../store/global';
import { getFilesFilteredByFolder } from '../utils/file.filters';

export interface Folder {
    [index: string]: Folder;
}

interface FileTableContentProps {
    files: FileEntry[];
    order: Order;
    orderBy: keyof FileEntry;
    page: number;
    allFolders: Folder;
    rowsPerPage: number;
    isSelected: Function;
    handleClick: Function;
    emptyRows: number;
    setFiles: Function;
    windowWidth: number;
    updateFileList: Function;
    folders: Folder;
    openDirectory: (name: string) => void;
    path: string[];
    setFolders: (folders: Folder) => void;
    matterNumber: string;
}

function desc<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}


function getSorting<K extends keyof any>(
    order: Order,
    orderBy: K,
): (a: { [key in K]: number | string }, b: { [key in K]: number | string }) => number {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function getFolderCanBeBeleted(files: FileEntry[], path: string[]) {
    return files.filter(item => item.fileName.startsWith(path.join("/")))
}

export function FileTableContent({ files, order, orderBy, page, rowsPerPage, isSelected, handleClick, matterNumber, setFiles, windowWidth, updateFileList, allFolders, folders, path, openDirectory, setFolders }: FileTableContentProps) {
    const global = useContext(globalContext);

    return <TableBody>
        {Object.keys(folders).map((key, index) =>
            (!key.includes('Deleted-') || global.state.showDeleted) && <FolderTableRow
                disabledDelete={getFolderCanBeBeleted(files, [...path, key]).length > 0}
                path={path}
                folders={allFolders}
                setFolders={setFolders}
                windowWidth={windowWidth}
                key={key}
                isSelected={isSelected}
                index={index}
                folderName={key}
                handleClick={handleClick}
                openDirectory={() => openDirectory(key.includes('Deleted-') ? key.substring(28, key.length) : key)}
                matterNumber={matterNumber}
            />
        )}

        {stableSort(getFilesFilteredByFolder(files, path), getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
                if (row.fileName.includes('Deleted-') && !global.state.showDeleted)
                    return;

                if (row.fileName.includes("MediaCompatible-y5mWgb-Internal-Transcoded-"))
                    return;

                return <FileTableRow
                    matterNumber={matterNumber}
                    windowWidth={windowWidth}
                    key={index}
                    isSelected={isSelected}
                    index={index + Object.keys(folders).length}
                    row={row}
                    handleClick={handleClick}
                    files={files}
                    setFiles={setFiles}
                    updateFileList={updateFileList}
                />
            }
            )}
    </TableBody>
}