import React from "react";
import { css } from "@emotion/core";
import { FormWrapper } from "../molecule/form.wrapper";
import { LoginForm } from "../organism/login.form";
/** @jsx jsx */ 
import { jsx } from '@emotion/core';
import { Logo } from "../molecule/logo";
import { Box } from "@material-ui/core";

const loginCSS = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export function LoginPage() {
    return <Box>
        <Logo type="white" />
        <FormWrapper >
            <LoginForm />
        </FormWrapper>
    </Box>;
}