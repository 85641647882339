
import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Input, DialogActions, DialogContentText } from '@material-ui/core';
import { Button } from '../atom/button';
import { Folder } from '../organism/file.table.content';
import { CreateFolderDialog } from './create-folder.dialog';
import { searchForKeyEntry } from './utils';

interface FolderDialogProps extends DialogActions {
    handleCreate: (name: string) => void;
    handleRestore: (name: string) => void;
    folders: Folder;
    path: string[];
}

interface FolderDeleteDialogProps extends DialogActions {
    handleDelete:() => void;
    folderName: string;
} 

interface FolderRestoreDialogProps extends DialogActions {
    handleRestore:() => void;
    folderName: string;
} 

interface DialogActions {
    open: boolean;
    setOpen: (state: boolean) => void;
}

export function FolderDialog({ open, setOpen, handleCreate, handleRestore, folders, path }: FolderDialogProps) {
    const [name, setName] = useState("");
    const [processing, setProcessing] = useState(false);
    const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState<boolean>(false);
    const [restoredFolderName, setRestoredFolderName]  = useState<string>("");

    useEffect(() => {
        if(open) {
            setOpenCreateFolderDialog(false);
        }
    },[open]);

    function postData() {
        setProcessing(true);

        const deletedFolder = searchForKeyEntry(folders, name, path);
    
        if (deletedFolder) {
            setOpenCreateFolderDialog(true)
            setProcessing(false);
            setRestoredFolderName(deletedFolder as string);
        } else {
            createFolder();
        }
    }

    function createFolder() {
        if(name) {
            handleCreate(name.trim());
            setName("");
            setProcessing(false);
        }
    }

    function restoreFolder() {
        setOpen(false);
        handleRestore(restoredFolderName);
        setName("");
        setProcessing(false);
    }

    return <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
    >
        <DialogTitle>Create Folder</DialogTitle>
        <DialogContent>
            <Input id='alert-dialog-description' value={name} onChange={(e: any) => setName(e.target.value)}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)} intent='danger'>
                Cancel
                </Button>
            <Button disabled={processing} loading={processing} onClick={() => postData()} intent='primary' autoFocus>
                Create
                </Button>
        </DialogActions>
        <CreateFolderDialog open={openCreateFolderDialog} setOpen={setOpenCreateFolderDialog} handleCreate={() => restoreFolder()}/>
    </Dialog>
}

export function DeleteFolderDialog({ open, setOpen, handleDelete, folderName }: FolderDeleteDialogProps) {

    return <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
    >
        <DialogTitle>Delete Folder</DialogTitle>
        <DialogContent>
            <DialogContentText id='alert-dialog-description'>
                Do you want to delete {folderName} folder?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)} intent='danger'>
                Cancel
                </Button>
            <Button onClick={() => handleDelete()} intent='primary' autoFocus>
                Delete
                </Button>
        </DialogActions>
    </Dialog>
}

export function RestoreFolderDialog({ open, setOpen, handleRestore, folderName }: FolderRestoreDialogProps) {

    return <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
    >
        <DialogTitle>Restore Folder</DialogTitle>
        <DialogContent>
            <DialogContentText id='alert-dialog-description'>
                Do you want to restore {folderName?.substring(28, folderName.length)} folder?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)} intent='danger'>
                Cancel
                </Button>
            <Button onClick={() => handleRestore()} intent='primary' autoFocus>
                Restore
                </Button>
        </DialogActions>
    </Dialog>
}