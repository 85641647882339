import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Button } from '../atom/button';
import { css } from '@emotion/core';

interface CreateFolderDialogProps {
    open: boolean;
    setOpen: Function;
    handleCreate: Function;
    name?: string;
}

const boldLabelCSS = css`
    font-weight: bold;
`;

export function CreateFolderDialog({ open, setOpen, handleCreate, name}: CreateFolderDialogProps) {

    const handleAction = () => {
        handleCreate();
    }

    return <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
        <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
            <DialogContentText id='alert-dialog-description'>
                The folder with the same name already exists (archived).<br/>
                If you would like to restore it with with all content Please click "<span css={boldLabelCSS}>Yes</span>".<br/>
                To create a new folder please click "<span css={boldLabelCSS}>No</span>" and use a unique name.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} intent=' danger'>
                    No
                </Button>
                <Button onClick={handleAction} intent='primary' autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
}
