import React, { useRef, useState } from 'react';
import { Box } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { SECONDARY_COLOR } from '../styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { FormHeader } from '../atom/form.header';
import { Button } from '../atom/button';
import { MainButtonCSS } from './login.form';
import { FileUpload } from '../molecule/file.upload';
import { FileUploadProgress } from './file.upload.modal';
import { FileSameNameDialog } from '../molecule/file.same.name.dialog';
import { FileEntry } from './file.table';
import { Folder } from './file.table.content';
import { getCurrentFolder, postChanges } from '../molecule/file.actions';

const dropzoneCSS = css`
    
    height: 220px;
    border-radius: 20px;
    background-color: ${SECONDARY_COLOR}10;
    border: 2px dashed ${SECONDARY_COLOR};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`;

const uploadIconCSS = css`
    color: ${SECONDARY_COLOR};
    font-size: 52px !important;
`;

const fileInputCSS = css`
    display: none;
`;

const browseButtonCSS = css`
    width: 160px;
    height: 38px;
    border-radius: 10px !important;
    padding: 6px 24px !important;
    margin-left: 10px !important;
`;

const uploadProcessCSS = css`
    margin-top: 10px; 
    max-height: 400px;
    overflow: auto;
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${SECONDARY_COLOR}; 
        border-radius: 10px;
    }

`;

const uploadContainerCSS = css`
    display: flex;
    flex-direction: column;
    width: 80%;
`;

const doneCSS = css`
    display: flex;
    justify-content: flex-end;
`;

export interface FileUpload {
    name: string;
    lastModified: number;
    lastModifiedDate: Date;
    webkitRelativePath: string;
    size: number;
    type: string;
}

const uploadedLabelCSS = (filesNum: number) => css`
margin-top: 20px;
    display: ${filesNum > 0 ? 'block' : 'none'};
`;

interface FileDropzoneProps {
    fileUploads: FileUploadProgress[];
    handleClose: Function;
    serverFiles: FileEntry[];
    path: string[];
    existingDirecotries: Folder;
    setExistingDirectories: (foled: Folder) => void;
    matterNumber: string;
}

function handleAddFiles(array: any, path: string[], setOpenFileSameNameDialog: React.Dispatch<React.SetStateAction<boolean>>, setFiles: React.Dispatch<React.SetStateAction<FileUpload[]>>, serverFiles: FileEntry[], files: FileUpload[]) {
    // check if already loading same file, check if already uploaded on server
    if (checkIfAlreadyLoadingFileSameName(array, files, path) || checkIfAlreadyPresentFileSameName(serverFiles, array, path)) {
        setOpenFileSameNameDialog(true);
    } else {
        const data = new FormData();
        for (const file of array) {
            data.append('file', file);
        }
        setFiles([...files, ...array]);
    }
}

function checkIfAlreadyLoadingFileSameName(array: any, files: FileUpload[], path: string[]) {
    const pathFix = path.length ? "/" : "";
    for (const file of array) {
        if (files.some(fileUpload => fileUpload.name === path.join("/") + pathFix + file.name)) {
            return true;
        }
    }
    return false;
}

function checkIfAlreadyPresentFileSameName(serverFiles: FileEntry[], array: any[], path: string[]) {
    const pathFix = path.length ? "/" : "";

    for (const file of array) {
        if (serverFiles.some(fileEntry => fileEntry.fileName === path.join("/") + pathFix + file.name)) {
            return true;
        }
    }
    return false;
}

async function onFilesAdded(evt: any, path: string[], setFilesToUpload: React.Dispatch<React.SetStateAction<any>>, setOpenFileSameNameDialog: React.Dispatch<React.SetStateAction<boolean>>, setFiles: React.Dispatch<React.SetStateAction<FileUpload[]>>,
    serverFiles: FileEntry[], files: FileUpload[]) {
    const transferedFiles = evt.target.files;
    const array = transferedFiles as FileUpload[];
    handleAddFiles(array, path, setOpenFileSameNameDialog, setFiles, serverFiles, files);
    setFilesToUpload(array);
}

async function onDrop(evt: any, path: string[], setFilesToUpload: React.Dispatch<React.SetStateAction<any>>, setOpenFileSameNameDialog: React.Dispatch<React.SetStateAction<boolean>>, setFiles: React.Dispatch<React.SetStateAction<FileUpload[]>>,
    serverFiles: FileEntry[], files: FileUpload[], exisitngDirectories: Folder, setExistingDirectories: (folders: any) => void, matterNumber: string) {
    evt.preventDefault();

    const [filesProcessed, directoriesProcessed] = await getAllFileEntries(evt.dataTransfer.items);

    const arr: any = [];

    for(const fileProcessed of filesProcessed) {
        const moved = getFile(fileProcessed);
        
        arr.push(moved);
    }    

    const transferedFiles: any[] = await Promise.all(arr);

    for(const [index,transferedFile] of transferedFiles.entries()) {
        let newPath = filesProcessed[index].fullPath;

        newPath = newPath.split("/");
        newPath.pop();
        newPath.shift();
        newPath = newPath.join("/");

        if(newPath.length > 0)
            transferedFile.newFilePath = newPath + "/";
    }    


    const array = transferedFiles as FileUpload[];
    addDirectoriesTreeToSalesforce(directoriesProcessed, exisitngDirectories, setExistingDirectories, matterNumber, path);
    handleAddFiles(array, path, setOpenFileSameNameDialog, setFiles, serverFiles, files);

    setFilesToUpload(array);
}

function addDirectoriesTreeToSalesforce(directories: string[], exisitngDirectories: Folder, setExistingDirectories: (folders: any) => void, matterNumber: string, path: string[]){
    let curDirectory = getCurrentFolder(path, exisitngDirectories);

    directories.forEach(directoryName => {
        const iterablePath = directoryName.split("/");
        let tempDirectory = curDirectory;

        iterablePath.shift();

        iterablePath.forEach((pathCell, index) => {
            if(!tempDirectory[pathCell] && index !== iterablePath.length - 1) {
                tempDirectory[pathCell] = {};
            } else if(!tempDirectory[pathCell] && index === iterablePath.length - 1) {
                tempDirectory[pathCell] = null as unknown as any;
            }

            tempDirectory = tempDirectory[pathCell];
        });
    });

    postChanges(exisitngDirectories, setExistingDirectories, matterNumber);
}

function handleRewriteSameName(setOpenFileSameNameDialog: React.Dispatch<React.SetStateAction<boolean>>, filesToUpload: any, files: FileUpload[], setFiles: React.Dispatch<React.SetStateAction<FileUpload[]>>) {
    setOpenFileSameNameDialog(false);
    const data = new FormData();
    for (const file of filesToUpload) {
        data.append('file', file);
    }
    setFiles([...files, ...filesToUpload]);
}

async function getAllFileEntries(dataTransferItemList: any) {
    let fileEntries = [];
    
    let queue = [];

    let directoriesPath = [];

    for (let i = 0; i < dataTransferItemList.length; i++) {
        queue.push(dataTransferItemList[i].webkitGetAsEntry());
    }
    while (queue.length > 0) {
        let entry: any = queue.shift();
        if (entry.isFile) {
            fileEntries.push(entry);

        } else if (entry.isDirectory) {
            let reader: any = entry.createReader();

            directoriesPath.push(entry.fullPath);

            const file = await readAllDirectoryEntries(reader);

            queue.push(...file);
        }
    }

    return [fileEntries, directoriesPath];
}

async function readAllDirectoryEntries(directoryReader: any) {
    let entries = [];
    let readEntries = await readEntriesPromise(directoryReader) as any;
    while (readEntries.length > 0) {
        entries.push(...readEntries);
        readEntries = await readEntriesPromise(directoryReader);
    }
    return entries;
}

async function readEntriesPromise(directoryReader: any) {
    try {
        return await new Promise((resolve, reject) => {
            directoryReader.readEntries(resolve, reject);
        });
    } catch (err) {
        console.log(err);
    }
}

async function getFile(fileEntry: any) {
    try {
        return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    } catch (err) {
        console.log(err);
    }
}

export function FileDropzone({ fileUploads, handleClose, serverFiles, path, existingDirecotries, setExistingDirectories, matterNumber }: FileDropzoneProps) {
    const [files, setFiles] = useState<FileUpload[]>([]);
    const [filesToUpload, setFilesToUpload] = useState<any>([]);
    const [openFileSameNameDialog, setOpenFileSameNameDialog] = useState<boolean>(false);

    const fileInputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

    return <Box css={uploadContainerCSS}>
        <Box css={dropzoneCSS} onDragOver={(evt) => evt.preventDefault()} onDrop={(evt) => onDrop(evt, path, setFilesToUpload, setOpenFileSameNameDialog, setFiles, serverFiles, files, existingDirecotries, setExistingDirectories, matterNumber)}>
            <CloudUploadIcon css={uploadIconCSS} />
            <FormHeader text={'Drag&Drop your files here'} />
            <span>
                OR
        </span>
            <input
                ref={fileInputRef}
                type="file"
                multiple
                css={fileInputCSS}
                onChange={(evt) => onFilesAdded(evt, path, setFilesToUpload, setOpenFileSameNameDialog, setFiles, serverFiles, files)}
                onClick={() => {
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                }}
            />
            <Button onClick={() => fileInputRef.current && fileInputRef.current.click()} intent='primary' css={[MainButtonCSS && browseButtonCSS]}>
                Browse Files
        </Button>
        </Box>
        <div css={uploadedLabelCSS(files.length)}>
            <FormHeader text='Uploaded Files' />
        </div>
        <Box css={uploadProcessCSS}>
            {files.map((file: any, index: any) => {
                return <FileUpload caseNumber={matterNumber} path={path} key={index} fileKey={index} fileName={file.name} size={file.size} file={file} fileUploads={fileUploads} />;
            })}
        </Box>
        <Box css={doneCSS}>
            <Button intent='success' onClick={handleClose}>
                Done
            </Button>
        </Box>
        <FileSameNameDialog handleCancel={() => setOpenFileSameNameDialog(false)} handleRewrite={() => handleRewriteSameName(setOpenFileSameNameDialog, filesToUpload, files, setFiles)} open={openFileSameNameDialog} />
    </Box>;
}