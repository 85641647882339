import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { css } from "@emotion/core";
import print from 'print-js';
import PrintIcon from '@material-ui/icons/Print';

interface PDFProps {
    file: Uint8Array;
    fileName: string;
    setFile: () => void;
    downloadFile: () => void;
}

const pdfCSS = css`
    position: fixed;
    width: 100vw; 
    height: 100vh;
    z-index: 1000; 
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.65);
`;

const wrapperCSS = css`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const imageCSS = css`
    height: calc(100vh - 50px);
    margin-top: 50px;
    object-fit: contain;
    width: 100vw;
`;

const nameCSS = css`
    background: #28292d;
    top: 0;
    position:fixed;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    color: white;
    height: 50px;
    padding-left: 10px;
    font-size: 18px;
`;

function Uint8ToString(u8a: Uint8Array) {
    const CHUNK_SZ = 50000;
    const c = [];
    for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
        c.push(String.fromCharCode.apply(null, u8a.slice(i, i + CHUNK_SZ) as unknown as number[]));
    }
    return c.join("");
}

export function IconViewer({ file, fileName, setFile, downloadFile }: PDFProps) {
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesCount, setPagesCOunt] = useState(1);

    return <div css={pdfCSS}>
        <div css={nameCSS}>
            <div>
                {fileName}
            </div>
            <div>
                <IconButton color='inherit' aria-label='print file' component='button' onClick={() => {
                    print('data:image/png;base64,' + btoa(Uint8ToString(file)), "image");
                }}>
                    <PrintIcon />
                </IconButton>
                <IconButton color='inherit' aria-label='download file' component='button' onClick={downloadFile}>
                    <CloudDownloadOutlinedIcon />
                </IconButton>
                <IconButton color='inherit' aria-label='close' component='button' onClick={() => setFile()}>
                    <CloseIcon />
                </IconButton>
            </div>
        </div>
        <div css={wrapperCSS}>
            <img  css={imageCSS} src={'data:image/png;base64,' + btoa(Uint8ToString(file))} />
        </div>
    </div>
}