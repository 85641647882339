import React, { useState, useCallback } from 'react';
import { Modal, makeStyles, Theme, createStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { headerCSS } from '../molecule/table.toolbar';
import { FormHeader } from '../atom/form.header';
import { FileDropzone } from './file.dropzone';
import { FileUploadCancelDialog } from '../molecule/file.upload.cancel.dialog';
import { FileEntry } from './file.table';
import { Folder } from './file.table.content';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            padding: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            width: 700,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            borderRadius: '20px',
            padding: theme.spacing(2, 4, 3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    }),
);

interface FileUploadModalProps {
    caseName: string;
    openedModal: boolean;
    setOpenModal: Function;
    updateFileList: Function;
    serverFiles: FileEntry[];
    path: string[];
    existingDirecotries: Folder;
    setExistingDirectories: (folders: Folder) => void;
    matterNumber: string;
}

export interface FileUploadProgress {
    fileName: any;
    cancelSource: any;
    ended: boolean;
}

const closeButtonCSS = css`
    align-self: flex-end;
`;

function handleCloseModal(setOpenModal: Function, fileUploads: FileUploadProgress[], setOpenCancelDialog: any, updateFileList: Function) {
    if (checkAllUploadsCompleted(fileUploads)) {
        setOpenModal(false);
        fileUploads.length !== 0 && updateFileList();
    } else {
        setOpenCancelDialog(true);
    }
}

function handleCancelUpload(setUploadCancelLoading: any, fileUploads: FileUploadProgress[], setOpenCancelDialog: any, setOpenModal: Function, updateFileList: Function) {
    setUploadCancelLoading(true);
    fileUploads.filter(fileUpload => fileUpload.ended === false).forEach(fileUpload => fileUpload.cancelSource.cancel());
    setUploadCancelLoading(false);
    setOpenCancelDialog(false);
    setOpenModal(false);
    updateFileList();
}

function checkAllUploadsCompleted(fileUploads: FileUploadProgress[]): boolean {
    return fileUploads.filter(fileUpload => fileUpload.ended === false).length === 0;
}

export function FileUploadModal({ caseName, openedModal, setOpenModal, updateFileList, ...props }: FileUploadModalProps) {

    const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
    const [uploadCancelLoading, setUploadCancelLoading] = useState<boolean>(false);
    const classes = useStyles();

    const [fileUploads] = useState<FileUploadProgress[]>([]);

    return <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openedModal}
        onClose={() => handleCloseModal(setOpenModal, fileUploads, setOpenCancelDialog, updateFileList)}
        aria-labelledby="file-upload-modal-title" 
        className={classes.modal}
        
    >
        <div className={classes.paper}>
            <IconButton css={closeButtonCSS} onClick={() => handleCloseModal(setOpenModal, fileUploads, setOpenCancelDialog, updateFileList)} aria-label='close modal' component='button'>
                <CloseIcon />
            </IconButton>
            <FormHeader id="file-upload-modal-title" css={headerCSS} text={'Case ' + caseName} />
            <FileDropzone  fileUploads={fileUploads} handleClose={() => handleCloseModal(setOpenModal, fileUploads, setOpenCancelDialog, updateFileList)} {...props} />
            <FileUploadCancelDialog fileUploads={fileUploads} open={openCancelDialog} setOpen={setOpenCancelDialog}
                handleCancelUpload={() => handleCancelUpload(setUploadCancelLoading, fileUploads, setOpenCancelDialog, setOpenModal, updateFileList)} loading={uploadCancelLoading} />
        </div>
    </Modal>;

}