import React, { useState } from 'react';
import { Box } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { FileIcon } from '../atom/file.icon';
import { UploadActions } from './upload.actions';
import { UploadProgress } from './upload.progress';
import { FileUploadProgress } from '../organism/file.upload.modal';

interface FileUploadProps {
    fileName: string;
    size: number;
    file: any;
    fileUploads: FileUploadProgress[];
    fileKey: any;
    path: string[];
    caseNumber: string;
}

const fileUploadCSS = css`
    display: flex;
    padding: 10px 24px;
    align-items: center;
    justify-content: space-between;
`;

export interface UploadProgressInterface {
    loaded: number;
    total: number;
}

export function FileUpload({ fileName, size, file, fileUploads, fileKey, path, caseNumber }: FileUploadProps) {
    const fileNameSplitted = fileName.split('.');
    const fileType = fileNameSplitted[fileNameSplitted.length - 1];

    const [completed, setCompleted] = useState<number | string>(0);
    const [uploadProgress, setUploadProgress] = useState<UploadProgressInterface>({
        loaded: 0,
        total: 0
    });

    return <Box css={fileUploadCSS}>
        <FileIcon type={fileType} />
        <UploadProgress fileName={fileName} completed={completed} uploadProgress={uploadProgress} />
        <UploadActions caseNumber={caseNumber} path={path} fileKey={fileKey} setCompleted={setCompleted} file={file} fileUploads={fileUploads} setUploadProgress={setUploadProgress} />
    </Box>
}