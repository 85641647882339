import { IconButton } from "@material-ui/core";
import React, { Dispatch, memo, useCallback, useState } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import PrintIcon from '@material-ui/icons/Print';
import CloseIcon from '@material-ui/icons/Close';
import { css } from "@emotion/core";
import print from 'print-js';

interface PDFProps {
    file: Uint8Array;
    fileName: string;
    setFile: () => void;
    downloadFile: () => void;
}

const pdfCSS = css`
    position: fixed;
    width: 100vw; 
    height: 100vh;
    z-index: 1000; 
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.65);
`;

const pdfWrapperCSS = css`
    margin: auto;
    max-height: calc(100vh - 96px - 26px);
    overflow: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
`;

const controlsCSS = css`
    background: #28292d;
    bottom: 0;
    position:fixed;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    color: white;
    font-size: 16px;
`;


const nameCSS = css`
    background: #28292d;
    top: 0;
    position:fixed;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    color: white;
    height: 50px;
    padding-left: 10px;
    font-size: 18px;
`;

const pageInfoCSS = css`
    margin-bottom: 0;
`;

export const PDFViewer = memo(({ file, fileName, setFile, downloadFile }: PDFProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);

    return <div css={pdfCSS}>
        <div css={nameCSS}>
            <div>
                {fileName}
            </div>
            <div>
                <IconButton color='inherit' aria-label='print file' component='button' onClick={() => {
                    const typedArray = Uint8Array.from(file);
                    var blob = new Blob([typedArray], { type: "application/pdf" });
                    var url = window.URL.createObjectURL(blob);

                    print(url);
                }}>
                    <PrintIcon />
                </IconButton>
                <IconButton color='inherit' aria-label='download file' component='button' onClick={downloadFile}>
                    <CloudDownloadOutlinedIcon />
                </IconButton>
                <IconButton color='inherit' aria-label='open' component='button' onClick={() => {
                    const typedArray = Uint8Array.from(file);
                    const blob = new Blob([typedArray], { type: "application/pdf" });
                    const url = window.URL.createObjectURL(blob);

                    const win = window.open(url, '_blank');

                    if (win)
                        win.focus();
                }}>
                    <OpenInNewIcon />
                </IconButton>
                <IconButton color='inherit' aria-label='close' component='button' onClick={() => setFile()}>
                    <CloseIcon />
                </IconButton>
            </div>
        </div>
        <div css={pdfWrapperCSS}>
            <Doc
                file={file}
                pageNumber={pageNumber}
                onPagesCount={setPagesCount}
            />
        </div>

        <div css={controlsCSS}>
            <IconButton color='inherit' aria-label='prev page' component='button' onClick={() => pageNumber > 1 && setPageNumber(pageNumber - 1)}>
                <ArrowLeft />
            </IconButton>
            <div css={pageInfoCSS}>{pageNumber} / {pagesCount}</div>
            <IconButton color='inherit' aria-label='next page' component='button' onClick={() => pagesCount > pageNumber && setPageNumber(pageNumber + 1)}>
                <ArrowRight />
            </IconButton>
        </div>
    </div >
});

interface DocProps {
    file: Uint8Array;
    pageNumber: number;
    onPagesCount: (count: number) => void;
}

export const Doc = memo(({ file, pageNumber, onPagesCount }: DocProps) => {
    return (
        <Document
            file={{ data: file }}
            onLoadSuccess={(successEvent: any) => {
                onPagesCount(successEvent.numPages);
            }}
        >
            <Page css={{ overflow: "hidden" }} width={700} pageNumber={pageNumber} />
        </Document>
    );
});