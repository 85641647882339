import { css } from "@emotion/core";
/** @jsx jsx */ 
import { jsx } from '@emotion/core';
import React, { PropsWithChildren } from "react";
import { Box } from "@material-ui/core";

const mainPageLayout = css`
    min-height: 100vh;
    display: grid;
    grid-template-rows: 100px 1fr;
`;

interface MainPageLayoutProps {
    header: React.ReactNode;
    content: React.ReactNode;
}

export function MainPageLayout({ header, content }: MainPageLayoutProps) {
    return <Box css={mainPageLayout}>
        <Box>
            { header }
        </Box>
        <Box>
            { content }
        </Box>
    </Box>;
}