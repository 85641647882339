import React from 'react';
/** @jsx jsx */ 
import { jsx } from '@emotion/core';
import { css } from '@emotion/core';
import { makeStyles, Theme, createStyles, TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import { OrangeCheckbox } from '../atom/orange.checkbox';
import { FileEntry } from '../organism/file.table';
import classes from '*.module.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { LIGHT_GREY_TEXT } from '../styles';
import { rowSizingCSS } from './file.table.row';

const headerCSS = css`
    background-color: #F1F1F5 !important;
    box-shadow: none !important;
    border-radius: 10px !important;
    font-weight: bold !important;
`;

export type Order = 'asc' | 'desc';

interface TableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof FileEntry) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    windowWidth: number;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export interface HeadCell {
    id: keyof FileEntry;
    label: string;
    numeric: boolean;
    colSpan?: number;
}

export const headCells: HeadCell[] = [
    { id: 'fileName', numeric: false, label: 'File Name', colSpan: 2 },
  { id: 'fileType', numeric: false, label: 'File Type' },
  { id: 'uploadDate', numeric: false, label: 'Last Modified' },
  { id: 'size', numeric: true, label: 'Size', colSpan: 2 },
];

export const mobileHeadCells: HeadCell[] = [
    { id: 'fileName', numeric: false, label: 'File', colSpan: 2 }
];

const headerCellCSS = (cellId: string) => css`
    width: ${cellId === 'fileName' ? '45%' : 'unset'};
  color: ${LIGHT_GREY_TEXT} !important;
`;

export function FileTableHeader(props: TableProps) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    if (props.windowWidth < 830) {
        return <TableHead css={headerCSS}>
            <TableRow css={rowSizingCSS}>
                {mobileHeadCells.map(headCell => (
                    <TableCell key={headCell.id} colSpan={headCell.colSpan || 1} align='left' css={headerCellCSS(headCell.id)}>
                        {headCell.label}
                    </TableCell>
                ))}
                <TableCell align='left' css={headerCellCSS}>
                    Action
                </TableCell>
            </TableRow>
        </TableHead>
    } else {
        return <TableHead css={headerCSS}>
            <TableRow>
                <TableCell padding="checkbox">
                    <OrangeCheckbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        // checked={rowCount === 0 ? false : numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all files' }}
                    />
                </TableCell>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        colSpan={headCell.colSpan || 1}
                        sortDirection={orderBy === headCell.id ? order : false}
                        css={headerCellCSS(headCell.id)}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={(event) => onRequestSort(event, headCell.id)}
                            IconComponent={ArrowDropDownIcon}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell align='left' css={headerCellCSS}>
                    Action
        </TableCell>
            </TableRow>
        </TableHead>;
    }

    
            
}