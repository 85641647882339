import React from "react";
import { Dialog, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { VideoPlayer } from "../atom/video";

interface MediaDialogProps {
    open: boolean;
    setOpen: (val: boolean) => void;

    caseNumber: string;

    fileName: string;
}
export function VideoDialog({ open, setOpen, caseNumber, fileName }: MediaDialogProps) {

    const resourceLink = `/file/view/${caseNumber}/${fileName.split("/").join("~~~")}`;

    return <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
            style: {
                top: "-2%",
                maxWidth: "100%",
                overflow: "visible"
            }
        }}
    >
        <DialogContent css={{ padding: "0!important" }}>
            <div css={{
                minWidth: "862px",
                minHeight: "431px"
            }}>

                <DialogCloseButton onClick={() => setOpen(false)} />
                <VideoPlayer
                    options={{
                        controls: true,
                        responsive: true,
                        fluid: true,
                        sources: [{
                            src: resourceLink,
                            type: 'video/mp4'
                        }]
                    }}
                />
            </div>
        </DialogContent>
    </Dialog>
}

export function AudioDialog({ open, setOpen, caseNumber, fileName }: MediaDialogProps) {

    const resourceLink = `/file/view/${caseNumber}/${fileName.split("/").join("~~~")}`;

    return <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
            style: {
                top: "-2%",
                maxWidth: "100%",
                border: "none",
                boxShadow: "none",
                background: "transparent",
                overflow: "visible"
            }
        }}
    >
        <DialogContent css={{ padding: "0!important" }}>
            <DialogCloseButton onClick={() => setOpen(false)} />
            <div>
                <audio controls style={{ width: "600px", outline: "none" }}>
                    <source src={resourceLink} type="audio/mpeg" />
                </audio>
            </div>
        </DialogContent>
    </Dialog>
}


interface DialogCloseButtonProps {
    onClick?: () => void;
}

function DialogCloseButton({ onClick }: DialogCloseButtonProps) {
    return (
        <div
            onClick={onClick}
            style={{
                position: "absolute",
                top: "-32px",
                right: "-32px",
                cursor: "pointer",
                color: "#f1f3f4"
            }}
        >
            <CloseIcon fontSize="large" />
        </div>
    );
}