import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import { CheckboxProps, Checkbox } from '@material-ui/core';

export const OrangeCheckbox = withStyles({
    root: {
        '&$checked': {
          color: orange[500],
        },
      },
      checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)
