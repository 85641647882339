import AI from './ai.svg';
import AVI from './avi.svg';
import CDR from './cdr.svg';
import CSS from './css.svg';
import DOC from './doc.svg';
import DOCX from './docx.svg';
import DWG from './dwg.svg';
import EPS from './eps.svg';
import EXE from './exe.svg';
import HTML from './html.svg';
import JPG from './jpg.svg';
import MOV from './mov.svg';
import MP3 from './mp3.svg';
import MP4 from './mp4.svg';
import PDF from './pdf.svg';
import PHP from './php.svg';
import PNG from './png.svg';
import PPT from './ppt.svg';
import PSD from './psd.svg';
import RTF from './rtf.svg';
import TXT from './txt.svg';
import WAV from './wav.svg';
import XLS from './xls.svg';
import XLSX from './xlsx.svg';
import ZIP from './zip.svg';
import FOLDER from './folder.svg';

export const icons: { [key: string]: string } = {
    'ai': AI,
    'avi': AVI,
    'cdr': CDR,
    'css': CSS,
    'doc': DOC,
    'docx': DOCX,
    'dwg': DWG,
    'eps': EPS,
    'exe': EXE,
    'html': HTML,
    'jpg': JPG,
    'mov': MOV,
    'mp3': MP3,
    'mp4': MP4,
    'pdf': PDF,
    'php': PHP,
    'png': PNG,
    'ppt': PPT,
    'psd': PSD,
    'rtf': RTF,
    'txt': TXT,
    'wav': WAV,
    'wave': WAV,
    'xls': XLS,
    'xlsx': XLSX,
    'zip': ZIP,
    'folder': FOLDER
};