import React from 'react';
import { Box, LinearProgress, withStyles } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { MAIN_FONT_FAMILY, MAIN_TEXT_COLOR, GREEN_SUCCESS_COLOR } from '../styles';
import prettyBytes from "pretty-bytes";
import { UploadProgressInterface } from './file.upload';

interface UploadProgressProps {
    fileName: string;
    completed: number | string;
    uploadProgress: UploadProgressInterface;
}

const uploadProgressContainerCSS = css`
    displaY: flex;
    flex-direction: column;
    flex-grow: 2;
    width: 100%;
`;

const labelsCSS = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const bytesCSS = css`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
`;

const fileNameCSS = css`
    font-size: 14px;
    font-family: ${MAIN_FONT_FAMILY};
    color: ${MAIN_TEXT_COLOR};
`;

const completedCSS = css`
    font-size: 14px;
    color: #696974;
`;

const LightGreenLinearProgress = withStyles({
    root: {
        height: 8,
        backgroundColor: '#E2E2EA',
        borderRadius: 4
      },
      bar: {
        borderRadius: 4,
        backgroundColor: `${GREEN_SUCCESS_COLOR}`,
      },
})(LinearProgress);

export function UploadProgress({ fileName, completed, uploadProgress }: UploadProgressProps) {
    


    return <Box css={uploadProgressContainerCSS}>
        <Box css={labelsCSS}>
            <span css={fileNameCSS}>{fileName.substring(0,35)}</span>
            {fileName.length < 20 && <span>{prettyBytes(uploadProgress.loaded)} / {prettyBytes(uploadProgress.total)}</span>}
            <span css={completedCSS}>{typeof completed === "number" ? +completed.toFixed(2) +'%' : completed}</span>
        </Box>
        {fileName.length > 20 && <Box css={bytesCSS}>
             <span>{prettyBytes(uploadProgress.loaded)} / {prettyBytes(uploadProgress.total)}</span>
        </Box> }
        <LightGreenLinearProgress variant="determinate" value={typeof completed === "number" ? Math.round(completed) : 100} />
    </Box>
}