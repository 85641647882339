import React from "react";
/** @jsx jsx */ 
import { jsx, css } from "@emotion/core";
import { MAIN_TEXT_COLOR, HEADER_MAIN_SIZE, HEADER_MAIN_WEIGHT, MAIN_FONT_FAMILY } from "../styles";

const formHeaderCSS = css`
    color: ${MAIN_TEXT_COLOR};
    font-weight: ${HEADER_MAIN_WEIGHT};
    font-size: ${HEADER_MAIN_SIZE};
    font-family: ${MAIN_FONT_FAMILY};
`;

export interface FormHeaderProps {
    text: string;
    id?: string;
}

export function FormHeader({ text, id }: FormHeaderProps) {
    return <p id={id} css={formHeaderCSS}>
        { text }
    </p>
}