import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Button } from '../atom/button';

interface DeleteDialogProps {
    open: boolean;
    setOpen: Function;
    handleDelete: Function;
    selectedFiles?: string[];
    selectedFile?: string;
}

export function DeleteDialog({ open, setOpen, handleDelete, selectedFiles, selectedFile }: DeleteDialogProps) {

    return <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
        <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
            <DialogContentText id='alert-dialog-description'>
                Do you want to delete {selectedFile ? selectedFile : `these ${selectedFiles!.length} files`}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} intent='danger'>
                    Cancel
                </Button>
                <Button onClick={handleDelete} intent='primary' autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
}
