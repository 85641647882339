

export const PRIMARY_COLOR = "#A42351";
export const SECONDARY_COLOR = "#FF974A";

export const PRIMARY_BUTTON_SHADOW = "rgba(164, 35, 81, 0.5)";
export const PRIMARY_BUTTON_HOVER = "#891C42";

export const BLUE_DOWNLOAD_COLOR = "#50B5FF";
export const ORANGE_DELETE_COLOR = "#FC5A5A";
export const GREEN_SUCCESS_COLOR = "#3DD598";

export const MAIN_TEXT_COLOR = "#171725";
export const BUTTON_TEXT_COLOR = "#fff";
export const LIGHT_GREY_TEXT = '#92929D';

export const HEADER_MAIN_SIZE = "18px";
export const HEADER_MAIN_WEIGHT = "bold";
export const MAIN_FONT_FAMILY = "'Poppins', sans-serif";