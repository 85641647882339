import { Action, ActionFunctionAny } from "redux-actions";

type ActionHandler<State extends object, Payload> = (state: State, payload: Payload) => void;

class IReducer<State extends object> {
    private handlers: any;

    constructor() {
        this.handlers = {};
    }

    public defaultReducerFunction(state: State, action: Action<any> | ActionFunctionAny<Action<any>>) {
        const actionType = typeof action === "function" ? action.toString() : action.type;

        if (!this.handlers[actionType]) {
            return state;
        }

        const newState = { ...state };
        this.handlers[actionType](newState, (action as any).payload);

        return newState;
    }

    public on<T extends ActionFunctionAny<Action<any>>>(action: T, handler: ActionHandler<State, ReturnType<T>["payload"]>) {
        this.handlers[action.toString()] = handler;
    }

    public build() {
        return this.defaultReducerFunction.bind(this);
    }
}

export function createReducer<State extends object>() {
    return new IReducer<State>();
}
