import React, { useContext, useState, useEffect } from 'react';
import { Box, IconButton, Button, Menu, MenuItem, Tooltip, CircularProgress } from '@material-ui/core';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DeleteDialog } from './delete.dialog';
import { FileEntry } from '../organism/file.table';
import axios from 'axios';
import { globalContext, GlobalContext } from '../store/global';
import { MainCircularProgress } from '../atom/circular.progress';
import { css } from "@emotion/core";
import { FolderDialog, DeleteFolderDialog, RestoreFolderDialog } from './folder.dialog';
import { Folder } from '../organism/file.table.content';
import { setSession } from './archivate.dialog';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { PDFViewer } from './pdf.viewer';
import { IconViewer } from './icon.viewer';
import { AudioDialog, VideoDialog } from "./media.viewer";
import { SnackbarKey, useSnackbar } from "notistack";
import { SnackbarTranscodingContent } from "../atom/snack-bars";

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const streamSaver = require('streamsaver');

interface FileActionsProps {
    selectedFiles?: string[];
    fileName?: string;
    files: FileEntry[];
    setFiles: Function;
    processing: boolean;
    setProcessing: Function;
    updateFileList: Function;
    path: string[];
    folders: Folder;
    setFolders: (folders: Folder) => void;
    canAddFolders: boolean;
    matterNumber: string;
    fileType?: string;
    onlyMainShow?: boolean;
}

interface MainFileActionProps {
    typeOfFile: FileType;
    setOpenVideoDialog: (value: boolean) => void;
    setOpenAudioDialog: (value: boolean) => void;
    selectedFiles?: string[];
    fileName?: string;
    setProcessing: Function;
    matterNumber: string;
    setFile: React.Dispatch<React.SetStateAction<Uint8Array | undefined>>;
    global: GlobalContext;
}

interface FileType {
    isImage: boolean; 
    isVideo: boolean; 
    isAudio: boolean; 
    isPdf: boolean; 
    isDoc: boolean; 
    isVLCVideo: boolean; 
}

const undeleteCSS = css`
    color: #1976d2;
`;

const deleteCSS = css`
    color: rgb(220, 0, 78);
`;

const actionsContainerCSS = css`
    display: flex;
`;

const actionsCSS = css`
    display: flex;
    justify-content: flex-end;
`;

const folderActionsContainerCSS = css`
    display: flex;
    justify-content: flex-end;
`;

interface HiddenLink {
    download: string;
    href: string;
    index: number;
}

interface FolderActionProps {
    path: string[];
    folderName: string;
    folders: Folder;
    disabledDelete: boolean;
    matterNumber: string;
    setFolders: (folders: Folder) => void;
}

function handleCloseAdminActions(setAnchorEl: Function) {
    setAnchorEl(null);
}

function handleOpenDialog(fileName: string | undefined, selectedFiles: string[] | undefined, setOpenDeleteDialog: Function) {
    if (!fileName && selectedFiles) {
        if (selectedFiles.length !== 0)
            setOpenDeleteDialog(true);
    } else {
        setOpenDeleteDialog(true);
    }
}

function pump(reader: ReadableStreamDefaultReader<Uint8Array>, writer: any, resp: Response) {

    reader.read()
        .then(async res => {

            if (res.done)
                try {
                    await writer.close()
                } catch {
                    console.log("Already closed, restoring")
                }
            else
                writer.write(res.value).then(pump(reader, writer, resp))
        })

}

async function downloadForPreview(reader: ReadableStreamDefaultReader<Uint8Array>, resp: Response) {
    let res: any = [];
    let data = await reader.read();

    if (data.value)
        res = [...data.value];

    while (!data.done) {
        data = await reader.read();

        if (data.value) {
            res = [...res, ...data.value];
        }
    }

    return res;
}

function imitateProcessing(setProcessing: Function) {
    setProcessing(true);

    setTimeout(() => {
        setProcessing(false);
    }, 6000);

    return;
}

async function previewFile(fileName: string, caseNumber: string, setFile: any, mode: 'download' | 'view' = 'download') {
    const url = `/file/${mode}/${caseNumber}/${fileName.split("/").join("~~~")}`;
    const res = await fetch(url);

    if (res.body) {
        const reader = res.body.getReader();

        const file = await downloadForPreview(reader, res);
        setFile(file);
    }
}

async function checkIfTranscodedFileExists(fileName: string, caseNumber: string) {
    const url = `/file/is-transcoded/${caseNumber}/${fileName.split("/").join("~~~")}`;
    const res = await fetch(url);
    const text = await res.text();

    return res.status === 200 && text === "true";
}

async function previewFileInVLC(fileName: string, caseNumber: string) { 
    let fileNameNew = fileName.split("/").join("~~~"); 
 
    fileNameNew = fileNameNew?.replace(/[^a-zA-Z0-9.~]/g,'_'); 
 
    const url = `/expose/request/${caseNumber}/${fileName.split("/").join("~~~")}`; 
    const res = await fetch(url); 
    const text = await res.text(); 
 
    window.open("vlcsclas://play?token=" + text + "&" + "filename=" + fileNameNew.split("/").pop()); 
}


async function handleDownload(fileName: string | undefined, setProcessing: Function, selectedFiles: string[] | undefined, global: GlobalContext, caseNumber: string, setFile: (file: Uint8Array | undefined) => void) {
    if (fileName) {
        imitateProcessing(setProcessing);
        selectedFiles = selectedFiles ? [...selectedFiles, fileName] : [fileName];
    }

    if (selectedFiles && selectedFiles.length !== 0) {
        imitateProcessing(setProcessing);
        setFile(undefined);
        if (!window.WritableStream) {
            let tempLinks: HiddenLink[] = [];
            for (const [index, fileName] of selectedFiles.entries()) {

                tempLinks.push({
                    download: fileName,
                    href: `/file/download/${caseNumber}/${fileName.split("/").join("~~~")}`,
                    index: index + 1
                });
            }
            const hiddenContainer = document.createElement('div');

            hiddenContainer.style.display = 'none';
            hiddenContainer.id = 'hidden-container';

            for (const fileLink of tempLinks) {
                const linkNode = document.createElement('a');
                linkNode.setAttribute('download', fileLink.download);
                linkNode.setAttribute('href', fileLink.href);
                linkNode.id = fileLink.download + ' ' + fileLink.index;
                hiddenContainer.appendChild(linkNode);
            }

            const [body] = document.getElementsByTagName('body');

            body.appendChild(hiddenContainer);

            for (const [index, entry] of tempLinks.entries()) {
                setTimeout(() => {

                    if (document.getElementById(entry.download + ' ' + entry.index) != null) {
                        document.getElementById(entry.download + ' ' + entry.index)!.click();
                    }
                }, 5000);
            }
        } else {
            const filesToUpload: Promise<void | Response | undefined>[] = [];

            if (selectedFiles) {
                for (const fileName of selectedFiles) {
                    //Make Requests to download
                    const url = `/file/download/${caseNumber}/${fileName.split("/").join("~~~")}`;
                    const fileStream = streamSaver.createWriteStream(fileName);

                    const res = await fetch(url);

                    const readableStream = res.body;

                    // more optimized
                    if (window.WritableStream && readableStream && readableStream.pipeTo) {
                        filesToUpload.push(readableStream.pipeTo(fileStream));
                        continue;
                    }

                    const writer = fileStream.getWriter();

                    if (res.body) {
                        const reader = res.body.getReader();

                        pump(reader, writer, res);
                    }
                }
                setSession();

                for (const file of filesToUpload) {
                    await file;

                }

                setSession();

            }
        }

    }

}

async function handleDelete(setProcessing: Function, global: GlobalContext, fileName: string | undefined, setAnchorEl: Function, updateFileList: Function,
    selectedFiles: string[] | undefined, setOpenDeleteDialog: Function, caseNumber: string) {
    setProcessing(true);
    if (fileName) {
        await axios.delete(`/file/${caseNumber}`, { data: { fileName } });
        handleCloseAdminActions(setAnchorEl);
        setProcessing(false);
        // we need to reload files from server after this
        updateFileList();
    } else {
        if (selectedFiles) {
            const filesToDelete = selectedFiles.map(fileName => {
                if (!fileName.split("/").pop()?.startsWith('Deleted-')) {
                    return axios.delete(`/file/${caseNumber}`, { data: { fileName } });
                }
            });

            Promise.all(filesToDelete).then(res => {
                handleCloseAdminActions(setAnchorEl);
                setProcessing(false);
                // we need to reload files from server after this
                updateFileList();
            });
        }
    }
    setOpenDeleteDialog(false);
}

async function handleUndeleteFiles(setProcessing: Function, fileName: string | undefined, updateFileList: Function, global: GlobalContext, setAnchorEl: Function, selectedFiles: string[] | undefined, caseNumber: string) {
    setProcessing(true);

    if (fileName) {
        const { status } = await axios.put(`/file/activate/${caseNumber}`, { fileName });

        if (status === 200) {
            setProcessing(false);
            updateFileList();
            handleCloseAdminActions(setAnchorEl);
        }
    } else if (selectedFiles) {
        const filesToUndelete = selectedFiles.map(fileName => {
            if (fileName.split("/").pop()?.startsWith('Deleted-')) {
                return axios.put(`/file/activate/${caseNumber}`, { fileName });
            }
        });

        await Promise.all(filesToUndelete);

        setProcessing(false);
        updateFileList();
        handleCloseAdminActions(setAnchorEl);
    }
}

async function handleCreateFolder(name: string, path: string[], folders: Folder, setFolders: (folders: Folder) => void, matterNumber: string, setOpenFolderDialog: (open: boolean) => void) {
    if (name === undefined || name === null) {
        return;
    }

    let addedInFolders = getCurrentFolder(path, folders)

    addedInFolders[name.split("/").join("-")] = null as unknown as any;


    postChanges(folders, setFolders, matterNumber, setOpenFolderDialog);
}

async function handleDeleteFolder(name: string, path: string[], folders: Folder, setFolders: (folders: Folder) => void, matterNumber: string, setOpenFolderDialog: (open: boolean) => void) {
    if (name === undefined || name === null) {
        return;
    }

    let addedInFolders = getCurrentFolder(path, folders)

    const date = new Date().toISOString();

    addedInFolders[`Deleted-${date.split(".").shift()}-${name}`] = addedInFolders[name];;

    delete addedInFolders[name];

    postChanges(folders, setFolders, matterNumber, setOpenFolderDialog);
}

async function handleRestoreFolder(name: string, path: string[], folders: Folder, setFolders: (folders: Folder) => void, matterNumber: string, setOpenFolderDialog: (open: boolean) => void) {
    if (name === undefined || name === null) {
        return;
    }

    let addedInFolders = getCurrentFolder(path, folders)

    addedInFolders[name.substring(28, name.length)] = addedInFolders[name]

    delete addedInFolders[name];

    postChanges(folders, setFolders, matterNumber, setOpenFolderDialog);
}

export async function postChanges(folders: Folder, setFolders: (folders: Folder) => void, matterNumber: string, setOpenFolderDialog?: (open: boolean) => void) {
    await axios.post(`/folder`, { matterNumber, FolderConfiguration: JSON.stringify(folders) });

    const { data, status } = await axios.get(`/folder/${matterNumber}`);

    if (status === 200) {
        setFolders(data);
    }

    setOpenFolderDialog && setOpenFolderDialog(false);
}

export function getCurrentFolder(path: string[], folders: Folder) {
    return path.reduce((p, c) => {
        if (p[c] === null) {
            p[c] = {}
        }

        return p[c]
    }, folders);
}

async function handleDownloadArchiveFolder(matterNumber: string, setLoading: (open: boolean) => void, folderName: string, setOpen: (open: boolean) => void, paths?: string[]) {
    const url = `/file/folder/${matterNumber}`;
    const fileStream = streamSaver.createWriteStream(`${matterNumber}_${folderName}.zip`);

    setLoading(true);

    const str = (paths && paths.length > 0) ? `${paths.join("/")}/${folderName}` : folderName;
    const encodedString = Buffer.from(`${str}`).toString('base64');

    const res = await fetch(url, {
        headers: {
            ...{ "X-FOLDER-NAME": `${encodedString}` }
        }
    });


    const requestId = res.headers.get("X-DOWNLOAD-REQUEST-ID");
    const readableStream = res.body;

    if (!window.WritableStream || !readableStream || !readableStream.pipeTo) {
        return;
    }

    const file = readableStream.pipeTo(fileStream);

    if (res.status !== 200) {
        setOpen(true);
        return;
    }

    if (res.body) {
        try {
            const writer = fileStream.getWriter();
            const reader = res.body.getReader();
            pump(reader, writer, res);
        } catch (e) {
            console.log(e);
        }
    }

    setSession();

    await file;
    await axios.post('/file/folder/confirm', { requestId });

    setLoading(false);
}

async function handleDownloadArchive(matterNumber: string, setLoading: (open: boolean) => void, setOpen: (open: boolean) => void, paths?: string[]) {
    const url = `/file/folder/${matterNumber}`;
    const folder = paths && paths.length > 0 ? `_${paths.join("_")}` : "";
    const fileStream = streamSaver.createWriteStream(`${matterNumber}${folder}.zip`);

    setLoading(true);

    const str = (paths && paths.length > 0) && `${paths.join("/")}`;
    const encodedString = str && Buffer.from(`${str}`).toString('base64');

    const res = await fetch(url, {
        headers: {
            ...(paths && paths.length > 0) ? { "X-FOLDER-NAME": `${encodedString}` } : {}
        }
    });

    const requestId = res.headers.get("X-DOWNLOAD-REQUEST-ID");
    const readableStream = res.body;

    if (!window.WritableStream || !readableStream || !readableStream.pipeTo) {
        return;
    }

    const file = readableStream.pipeTo(fileStream);

    setTimeout(async () => {
        if (res.status !== 200) {
            setOpen(true);
            return;
        }

        if (res.body) {
            try {
                const writer = fileStream.getWriter();
                const reader = res.body.getReader();
                pump(reader, writer, res);
            } catch (e) {
                console.log(e);
            }
        }

        setSession();

        await file;

        await axios.post('/file/folder/confirm', { requestId });
        setLoading(false);
    }, 3000);
}

export function MainFileActions ({ global, selectedFiles, fileName, setProcessing, matterNumber, typeOfFile, setOpenVideoDialog, setOpenAudioDialog, setFile}: MainFileActionProps) {
    const {isImage, isVideo, isAudio, isPdf, isDoc, isVLCVideo} = typeOfFile;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    return (
    <>
    {fileName && (isImage || isVideo || isAudio || isPdf || isDoc || isVLCVideo) &&
        <Tooltip title="preview" aria-label="preview">
            <IconButton color='primary' aria-label='preview file' component='button' onClick={async () => {

                if (isVLCVideo) {
                    return previewFileInVLC(fileName, matterNumber);

                } else if (isVideo) {
                    return setOpenVideoDialog(true);

                } else if (isAudio) {
                    return setOpenAudioDialog(true);

                } else if (isDoc || isPdf || isImage) {
                    const generalSnack = enqueueSnackbar(<div> Opening <b style={{ letterSpacing: "0.5px" }}>{fileName}</b> </div>, { variant: 'info', persist: true });
                    let transcoderKey: SnackbarKey | undefined = undefined;

                    if (isDoc) {
                        const isTranscodedExists = await checkIfTranscodedFileExists(fileName, matterNumber);

                        if (!isTranscodedExists) {
                            transcoderKey = enqueueSnackbar("", {
                                variant: 'info',
                                persist: true,
                                content: (key, message) => <SnackbarTranscodingContent key={key} filename={fileName} message={message} />
                            });
                        }
                    }

                    await previewFile(fileName, matterNumber, setFile, isDoc ? "view" : "download");

                    setTimeout(() => {
                        closeSnackbar(transcoderKey);
                        closeSnackbar(generalSnack);
                    }, 3500);
                }

            }}>
                <VisibilityIcon />
            </IconButton>
        </Tooltip>}
    <Tooltip title="download" aria-label="download">
        <IconButton color='primary' aria-label='download file' component='button' onClick={() => handleDownload(fileName, setProcessing, selectedFiles, global, matterNumber, setFile)}>
            <CloudDownloadOutlinedIcon />
        </IconButton>
    </Tooltip>
    </>
)};

export function FileActions({ selectedFiles, fileName, fileType, processing, setProcessing, updateFileList, path, folders, onlyMainShow, setFolders, matterNumber, canAddFolders }: FileActionsProps) {    
    const global = useContext(globalContext);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openFolderDialog, setOpenFolderDialog] = useState<boolean>(false);
    const [openVideoDialog, setOpenVideoDialog] = useState<boolean>(false);
    const [openAudioDialog, setOpenAudioDialog] = useState<boolean>(false);

    const [disabledDelete, setDisabledDelete] = useState<boolean>(false);
    const [disabledUndelete, setDisabledUndelete] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<Uint8Array>();
    const [open, setOpen] = useState(false);

    const [previewMessageOpened, setPreviewMessageOpened] = useState(false);

    // Format file types
    const formattedFileType = (fileType || "").toLocaleLowerCase();

    const [isImage] = useState(() => (
        formattedFileType === "png" ||
        formattedFileType === "jpg" ||
        formattedFileType === "jpeg" ||
        formattedFileType === "bmp" ||
        formattedFileType === "gif"
    ));

    const isVideo = formattedFileType === "mp4"
        || formattedFileType === "mpeg4"
        || formattedFileType === "m4v"
        || formattedFileType === "mov"
        || formattedFileType === "webm"
        || formattedFileType === "ogg";

    const isAudio = formattedFileType === "mp3"
        || formattedFileType === "wav"
        || formattedFileType === "wave";

    const isVLCVideo = formattedFileType === "avi"
        || formattedFileType === "wmv"
        || formattedFileType === "m4v"
        || formattedFileType === "mkv";

    const isDoc = formattedFileType === "doc" || formattedFileType === "docx";
    const isPdf = formattedFileType === "pdf";

    useEffect(() => {
        if (fileName) {
            if (fileName.split("/").pop()?.startsWith('Deleted-')) {
                setDisabledDelete(true);
            } else {
                setDisabledUndelete(true);
            }
        }
        if (global.state.user) {
            setCanDelete(global.state.user.role.toString() == "Admin");
        }
    }, []);

    return processing ? <Box><MainCircularProgress size={40} /></Box> :
        <>
            {file && (fileType === "pdf" || isDoc) &&
                <PDFViewer
                    setFile={() => setFile(undefined)}
                    fileName={fileName + ""}
                    file={file}
                    downloadFile={() => handleDownload(fileName, setProcessing, selectedFiles, global, matterNumber, setFile)} />
            }
            {file && isImage &&
                <IconViewer
                    setFile={() => setFile(undefined)}
                    fileName={fileName + ""}
                    file={file}
                    downloadFile={() => handleDownload(fileName, setProcessing, selectedFiles, global, matterNumber, setFile)} />
            }

            <Box css={actionsCSS}>
                {onlyMainShow ?
                <MainFileActions 
                    setOpenVideoDialog={setOpenVideoDialog} 
                    setOpenAudioDialog={setOpenAudioDialog} 
                    global={global} 
                    selectedFiles={selectedFiles} 
                    matterNumber={matterNumber} 
                    setProcessing={setProcessing} 
                    setFile={setFile} 
                    fileName={fileName}
                    typeOfFile={{
                        isImage,
                        isVideo,
                        isAudio,
                        isPdf,
                        isDoc,
                        isVLCVideo
                    }} 
                />
                :

                <Box css={actionsContainerCSS}>
                    <Button aria-controls="admin-menu" aria-haspopup="true" onClick={(event) => setAnchorEl(event.currentTarget)}>
                        <MoreVertIcon />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        {(isVideo || isAudio || isVLCVideo) && fileName && <MenuItem onClick={() => previewFileInVLC(fileName, matterNumber)}> View in VLC Player </MenuItem>}
                        {canDelete && <MenuItem onClick={() => handleUndeleteFiles(setProcessing, fileName, updateFileList, global, setAnchorEl, selectedFiles, matterNumber)} css={undeleteCSS} disabled={disabledUndelete}> Restore </MenuItem>}
                        {canDelete && <MenuItem onClick={() => handleOpenDialog(fileName, selectedFiles, setOpenDeleteDialog)} css={deleteCSS} disabled={disabledDelete}>Delete</MenuItem>}

                        {fileName === undefined && <MenuItem onClick={() => handleDownloadArchive(matterNumber, setLoading, setOpen, path)} css={deleteCSS} disabled={disabledDelete || loading}>Download All</MenuItem>}
                        {/* <MenuItem onClick={() => watchStream(matterNumber, connection)} css={deleteCSS} disabled={disabledDelete || loading}>Download All</MenuItem> */}
                        {canAddFolders && canDelete && <MenuItem onClick={() => setOpenFolderDialog(true)} css={deleteCSS}>Add Folder</MenuItem>}
                    </Menu>
                </Box>}

                <VideoDialog open={openVideoDialog} setOpen={setOpenVideoDialog} caseNumber={matterNumber} fileName={fileName + ""} />
                <AudioDialog open={openAudioDialog} setOpen={setOpenAudioDialog} caseNumber={matterNumber} fileName={fileName + ""} />

                <FolderDialog open={openFolderDialog} setOpen={setOpenFolderDialog} path={path} handleRestore={(name: string) => handleRestoreFolder(name, path, folders, setFolders, matterNumber, setOpenFolderDialog)} handleCreate={(name: string) => handleCreateFolder(name, path, folders, setFolders, matterNumber, setOpenFolderDialog)} folders={folders} />
                <DeleteDialog open={openDeleteDialog} setOpen={setOpenDeleteDialog} handleDelete={() => handleDelete(setProcessing, global, fileName, setAnchorEl, updateFileList, selectedFiles, setOpenDeleteDialog, matterNumber)} selectedFiles={selectedFiles} selectedFile={fileName} />
            </Box></>
}

export function FolderActions({ path, folderName, folders, disabledDelete, matterNumber, setFolders }: FolderActionProps) {
    const global = useContext(globalContext);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openDeleteFolderDialog, setOpenDeleteFolderDialog] = useState<boolean>(false);
    const [openRestoreFolderDialog, setOpenRestoreFolderDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);

    useEffect(() => {
        if (global.state.user) {
            setCanDelete(global.state.user.role.toString() == "Admin");
        }
    }, []);
    return <>
        <Box css={folderActionsContainerCSS}>
            <div></div>
            <Button aria-controls="admin-menu" aria-haspopup="true" onClick={(event) => setAnchorEl(event.currentTarget)}>
                <MoreVertIcon />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {canDelete && (folderName.includes('Deleted-') ? <MenuItem onClick={() => setOpenRestoreFolderDialog(true)} css={deleteCSS} > Restore </MenuItem> :
                    <MenuItem onClick={() => setOpenDeleteFolderDialog(true)} css={deleteCSS} >Delete</MenuItem>)
                }
                <MenuItem onClick={() => handleDownloadArchiveFolder(matterNumber, setLoading, folderName, setOpen, path)} css={deleteCSS} disabled={loading}>Download All</MenuItem>

                <DeleteFolderDialog folderName={folderName} open={openDeleteFolderDialog} setOpen={setOpenDeleteFolderDialog} handleDelete={() => handleDeleteFolder(folderName, path, folders, setFolders, matterNumber, setOpenDeleteFolderDialog)} />
                <RestoreFolderDialog folderName={folderName} open={openRestoreFolderDialog} setOpen={setOpenRestoreFolderDialog} handleRestore={() => handleRestoreFolder(folderName, path, folders, setFolders, matterNumber, setOpenDeleteFolderDialog)} />
            </Menu>

            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity="error">
                    File too large!
                </Alert>
            </Snackbar>
        </Box></>
}