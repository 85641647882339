import React, { useState, useContext } from 'react';
import { Badge, Box, Grid, IconButton, Tooltip } from '@material-ui/core';
import { FormHeader } from '../atom/form.header';
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { FileActions } from './file.actions';
import { Button } from '../atom/button';
import { MainButtonCSS, Relation } from '../organism/login.form';
import { LIGHT_GREY_TEXT } from '../styles';
import { FileUploadModal } from '../organism/file.upload.modal';
import { FileEntry } from '../organism/file.table';
import { globalContext } from '../store/global';
import { UserRole } from '../store/global/actions';
import { ArchivateDialog } from './archivate.dialog';
import UploadPath from '../file.icons/upload.svg';
import ArchivePath from '../file.icons/archive.svg';
import { Folder } from '../organism/file.table.content';
import { backFolder, moveBackCSS } from '../organism/folders.bar';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import HelpCenterIcon from "@material-ui/icons/Help";
import { HelpModal } from "./help.dialog";

interface TableToolBarProps {
    filesNum: number;
    selectedFiles: string[];
    folderName?: string;
    files: FileEntry[];
    setFiles: Function;
    updateFileList: Function;
    windowWidth: number;
    path: string[];
    folders: Folder;
    setFolders: (folders: Folder) => void;
    matter: Relation;
    caseNumber: string;
}

const additionalLabelCSS = css`
    padding: 0 10px;
    color: ${LIGHT_GREY_TEXT};
    font-size: 18px;
`;

export const headerCSS = css`
    font-size: 24px;
    padding: 0 10px;
`;

const defCSS = css`
    padding: 0 10px !important;
`;

const uploadButtonCSS = (windowWidth: number) => css`
    width: ${windowWidth < 830 ? '60px' : '130px'};
    height: 38px;
    border-radius: 10px !important;
    padding: 6px 24px !important;
    margin-left: 10px !important;
`;

const toolbarCSS = css`
    padding: 10px;
`;

const adminControlsCSS = css`
    display: flex !important;
`;

const smallBadge = css`
    & .MuiBadge-badge {
        transform: scale(0.7) translate(40%, -40%) !important;
    }
`;

const moveBackContainerCSS = css`
    margin-right: 5px !important;
`;

const fileActionsContainerCSS = (windowWidth: number) => css`
    display: ${windowWidth < 830 ? 'none' : 'flex'};
`;

function moveToMatterSelection() {
    window.location.href = "/matters";
}

export function TableToolBar({ filesNum, matter, selectedFiles, files, setFiles, updateFileList, windowWidth, caseNumber, ...props }: TableToolBarProps) {
    const global = useContext(globalContext);
    const userRole = global.state.user?.role ? global.state.user.role : UserRole.User;
    const [processing, setProcessing] = useState<boolean>(false);

    const [openedModal, setOpenedModal] = useState<boolean>(false);
    const [openedArchivate, setOpenedArchivate] = useState<boolean>(false);
    const [openedHelp, setOpenedHelp] = useState<boolean>(false);

    const openHelpPopup = () => {
        setOpenedHelp(true);
        localStorage.setItem('infoMessage', '0');
    }

    return <Box css={toolbarCSS}>
        <Grid container>
            <Grid item xs={windowWidth < 830 ? 12 : 6}>
                <Grid container>
                    {global.state.matters.length < -1 && <Grid onClick={moveToMatterSelection} css={moveBackContainerCSS} item>
                        <KeyboardBackspaceIcon css={[backFolder, moveBackCSS]} />
                    </Grid>}
                    <Grid item>
                        <FormHeader css={headerCSS} text={'Case ' + (matter.matterNumber ?? "")} />
                    </Grid>
                    <Grid item>
                        <p css={additionalLabelCSS}>({filesNum} items) </p>
                    </Grid>
                    <Grid item>

                    </Grid>
                    <Grid item>
                        <FormHeader css={headerCSS} text={matter.docketNumber ? ` Docket # ${matter.docketNumber} ` : ` Indictment # ${matter.indictment} `} />
                    </Grid>
                    <Grid css={defCSS} item>
                        <FormHeader css={headerCSS} text={matter.defendentsName} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={windowWidth < 830 ? 12 : 6}>
                <Grid container justify={windowWidth < 830 ? 'center' : 'flex-end'} alignItems="center">
                    <Grid item css={fileActionsContainerCSS(windowWidth)}>
                        <Tooltip title="Help Center" aria-label="help">
                            <IconButton color='primary' aria-label='help' component='button' onClick={() => openHelpPopup()}>
                                {localStorage.getItem("infoMessage") ? 
                                <HelpCenterIcon /> : 
                                <Badge badgeContent={1} color="secondary" css={smallBadge}>
                                    <HelpCenterIcon />
                                </Badge>
                                }
                            </IconButton>
                        </Tooltip>
                        <FileActions canAddFolders {...props} matterNumber={matter.matterNumber} processing={processing} setProcessing={setProcessing} selectedFiles={selectedFiles} files={files} setFiles={setFiles} updateFileList={updateFileList} />
                    </Grid>
                    {userRole.toString() == 'Admin' && <Grid item css={adminControlsCSS}>
                        <Tooltip title='Upload' placement='bottom'>
                            <div>
                                <Button intent='primary' onClick={() => setOpenedModal(!openedModal)} css={[MainButtonCSS && uploadButtonCSS(windowWidth)]}>
                                    <img src={UploadPath} />
                                </Button>
                            </div>
                        </Tooltip>
                        <Tooltip title='Backup' placement='bottom'>
                            <div>
                                <Button intent='primary' onClick={() => setOpenedArchivate(!openedArchivate)} css={[MainButtonCSS && uploadButtonCSS(windowWidth)]}>
                                    <img src={ArchivePath} />
                                </Button>
                            </div>
                        </Tooltip>

                        <FileUploadModal path={props.path} caseName={matter.matterNumber} openedModal={openedModal} setOpenModal={setOpenedModal} updateFileList={updateFileList} serverFiles={files}
                            existingDirecotries={props.folders} setExistingDirectories={props.setFolders} matterNumber={matter.matterNumber} />
                        <ArchivateDialog caseNumber={caseNumber} open={openedArchivate} setOpen={setOpenedArchivate} />

                    </Grid>}
                    <HelpModal open={openedHelp} onClose={() => setOpenedHelp(false)} />
                </Grid>
            </Grid>
        </Grid>

    </Box>
}