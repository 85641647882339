import React, { useContext, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Button } from '../atom/button';
import axios from 'axios';
import { globalContext, GlobalContext } from '../store/global';
import { WhiteCircularProgress } from '../atom/circular.progress';
import dayjs from 'dayjs';

interface ArchivateDialogProps {
    open: boolean;
    setOpen: Function;
    caseNumber: string;
}

export function setSession(count: number = 1, timeMeasure: "minute" | "hour" | "day" = "hour") {
    sessionStorage.setItem('sessionExpiration', dayjs().add(count, timeMeasure).format());
}  

async function handleArchivate(setLoading: Function, caseNumber: string, setOpen: Function) {
    setLoading(true);
    try {
        await axios.get(`/file/archivate/${caseNumber}`)
    } catch (err) {
        console.log(err);
    }
    setSession();
    setLoading(false);
    setOpen(false);
}


export function ArchivateDialog({ open, setOpen, caseNumber }: ArchivateDialogProps) {
    const global = useContext(globalContext);
    const [loading, setLoading] = useState<boolean>(false);

    return <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
    >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
            <DialogContentText id='alert-dialog-description'>
                Backup all files related to this case.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)} intent='danger'>
                Cancel
                </Button>
            <Button onClick={() => handleArchivate(setLoading, caseNumber, setOpen)} intent='primary' autoFocus disabled={loading}>
                {loading ? <WhiteCircularProgress size={24} /> : 'Backup'}
                </Button>
        </DialogActions>
    </Dialog>
}