import React, { useState, useContext, useEffect } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { css } from "@emotion/core";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ORANGE_DELETE_COLOR, GREEN_SUCCESS_COLOR } from '../styles';
import axios, { CancelTokenSource } from 'axios';
import { globalContext } from '../store/global';
import { MainCircularProgress } from '../atom/circular.progress';
import { FileUploadProgress } from '../organism/file.upload.modal';
import { setSession } from './archivate.dialog';

const visibleCSS = (visible: boolean) => css`
    display: ${visible ? 'inline-block' : 'none'} !important;
`;

const actionsContainer = css`
    display: flex;
    align-items: space-evenly;
    margin-left: 20px;
`;

const cancelButtonCSS = css`
    color: ${ORANGE_DELETE_COLOR};
`;

const completedCheckBox = (visible: boolean) => css`
    display: ${visible ? 'inline-block' : 'none'} !important;
    color: ${GREEN_SUCCESS_COLOR};
`;

const progressActionsCSS = css`
    display: flex;
`;

const circularProgressCSS = css`
    margin: 12px;
    flex: 0 0 auto;
`;

interface UploadActionsProps {
    setCompleted: Function;
    file: any;
    fileUploads: FileUploadProgress[];
    fileKey: any;
    path: string[];
    setUploadProgress: Function;
    caseNumber: string;
}

function handleCancel(source: CancelTokenSource, fileUploads: FileUploadProgress[], file: any, fileKey: any, setCompleted: Function, setCanceled: Function, path: string[]) {
    source.cancel();
    const pathFix = (path.length ? "/" : "") + (file.newFilePath || "");
    const filenameLong = path.join("/") + pathFix + file.name + fileKey;
    fileUploads.find(fileUpload => fileUpload.fileName == filenameLong)!.ended = true;
    setCompleted('Canceled');
    setCanceled(true);
}

export function UploadActions({ setCompleted, file, fileUploads, fileKey, path, setUploadProgress, caseNumber }: UploadActionsProps) {
    const [completed, setCompletedBool] = useState<boolean>(false);
    const [source, setSource] = useState(axios.CancelToken.source());
    const [canceled, setCanceled] = useState<boolean>(false);

    const global = useContext(globalContext);


    useEffect(() => {
        async function uploadFile() {
            const pathFix = (path.length ? "/" : "") + (file.newFilePath || "");
            const URIName = file.name.replace(/[!@#$%^&*()[\];:,/\\]/g, "_");
            const filenameLong = path.join("/") + pathFix + URIName + fileKey;
            const filenameShort = path.join("/") + pathFix + URIName;

            fileUploads.push({
                fileName: filenameLong,
                cancelSource: source,
                ended: false
            });

            setSession();
            await axios.post(`/file?matterName=${caseNumber}&fileName=${filenameShort}`, file, {
                onUploadProgress: function(ProgressEvent: any){
                    setUploadProgress({
                        loaded: ProgressEvent.loaded,
                        total: ProgressEvent.total
                    });
                    setCompleted(ProgressEvent.loaded / ProgressEvent.total * 100);
                },
                headers: {
                    'content-type': 'text/plain'
                },
                cancelToken: source.token
            });

            setCompleted('Uploaded');
            setSession();

            const uploaded = fileUploads.find(fileUpload => fileUpload.fileName === filenameLong);

            if (uploaded) {
                uploaded.ended = true
                setCompletedBool(true);
            } else {
                setCanceled(true);
            }

        }

        uploadFile();
    }, []);

    return <Box css={actionsContainer}>
        {(!completed && !canceled) ? <Box css={progressActionsCSS}><MainCircularProgress css={[visibleCSS(!completed) && circularProgressCSS]} size={24} />
            <IconButton css={visibleCSS(!completed)} onClick={() => handleCancel(source, fileUploads, file, fileKey, setCompleted, setCanceled, path)} aria-label='cancel'>
                <CancelOutlinedIcon css={cancelButtonCSS} />
            </IconButton>
        </Box> : <Box />}
        {canceled && <CancelOutlinedIcon css={cancelButtonCSS} />}
        <CheckBoxIcon css={completedCheckBox(completed)} aria-label='completed' />
    </Box>
}