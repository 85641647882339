import { Button as MButton } from '@material-ui/core';
import { css } from "@emotion/core";
import React, { PropsWithChildren } from "react";
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PRIMARY_COLOR, BUTTON_TEXT_COLOR, PRIMARY_BUTTON_SHADOW, PRIMARY_BUTTON_HOVER } from "../styles";

type ButtonIntent = "none" | "primary" | "success" | "warning" | "danger";

const baseButtonCSS = css`
    &.disabled {
        pointer-events: none;
    }

    letter-spacing: .3px;
    font-family: 'Poppins', sans-serif!important;

    border-radius: 10px;
    padding: 12px 24px;

    outline: none!important;
    position: relative;

    transition: .3s ease all;

    > .bp3-icon + span.bp3-button-text {
        padding-left: 24px;
    }

    > .bp3-spinner .bp3-spinner-track {
        stroke-width: 0px;
    }

    > .bp3-spinner .bp3-spinner-head {
        stroke-width: 12px;
        stroke: #ffffff;
    }

    > .bp3-icon {
        position: absolute;
        left: 6px;
        background: #386BE1;
        color: white;
        padding: 8px;
        border-radius: 19px;
        margin-right: 18px;

        transition: .3s ease background, .3s ease color;

        svg {
            width: 14px;
            height: 14px;
        }
    }
`;

type IntentColorsMap = {
    [key in ButtonIntent]?: {
        background: string;
        shadow: string;
        color: string;

        activeBackground: string;
        activeShadow: string;

        hoverBackground: string;
        hoverShadow: string;

        hoverIconColor: string;
        hoverIconBackground: string;
    };
};

const intentColorsMap: IntentColorsMap = {
    success: {
        background: "#11b471",
        shadow: "rgba(15,153,96, 0.5)",
        color: "#fff",

        hoverBackground: "#0f9960",
        hoverShadow: "rgba(14,136,85, 0.5)",

        activeBackground: "#0f9960",
        activeShadow: "rgba(127, 130, 138, 0.25)",

        hoverIconColor: "#0f9960",
        hoverIconBackground: "#f2f2f2"
    },

    danger: {
        background: "#d9534f",
        shadow: "rgba(217,83,79, 0.5)",
        color: "#fff",

        hoverBackground: "#ca3030",
        hoverShadow: "rgba(250,40,79, 0.5)",

        activeBackground: "#d9534f",
        activeShadow: "rgba(217,83,79, 0.25)",

        hoverIconColor: "#d9534f",
        hoverIconBackground: "#f2f2f2"
    },

    primary: {
        background: PRIMARY_COLOR,
        shadow: PRIMARY_BUTTON_SHADOW,
        color: BUTTON_TEXT_COLOR,

        hoverBackground: PRIMARY_BUTTON_HOVER,
        hoverShadow: PRIMARY_BUTTON_SHADOW,

        activeBackground: PRIMARY_BUTTON_HOVER,
        activeShadow: "rgba(127, 130, 138, 0.25)",

        hoverIconColor: PRIMARY_COLOR,
        hoverIconBackground: "#f2f2f2"
    }
};

function getIntentCSS(intent: ButtonIntent = "primary") {

    if (!intentColorsMap[intent])
        return;

    const colors = intentColorsMap[intent]!;

    return css`
        color: ${colors.color}!important;
        background: ${colors.background}!important;
        box-shadow: inset 0px 0px 0px rgba(11, 11, 11, 0), 0px 2px 10px ${colors.shadow}!important;

        &:hover {
            background: ${colors.hoverBackground}!important;
            box-shadow: inset 0px 0px 0px rgba(11, 11, 11, 0), 0px 5px 10px ${colors.hoverShadow}!important;

            > .bp3-icon {
                color: ${colors.hoverIconColor}!important;
                background: ${colors.hoverIconBackground}!important;
            }
        }

        &:active {
            background: ${colors.activeBackground}!important;
            box-shadow: inset 0px 2px 10px rgba(11, 11, 11, 0.35), 0px 5px 10px ${colors.activeShadow}!important;
        }
    `;
}

export function Button(props: any) {
    return <MButton css={[baseButtonCSS, getIntentCSS(props.intent)]} {...props} />;
}