import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Button } from '../atom/button';
import { WhiteCircularProgress } from '../atom/circular.progress';

interface FileUploadCancelDialogProps {
    open: boolean;
    setOpen: Function;
    handleCancelUpload: Function;
    loading: boolean;
    fileUploads: any;
}



export function FileUploadCancelDialog({ open, setOpen, handleCancelUpload, loading }: FileUploadCancelDialogProps) {

    return <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
    >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
            <DialogContentText id='alert-dialog-description'>
                You have uploads in progress. Do you want to cancel uploading?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)} intent='danger' disabled={loading}>
                No
                </Button>
            <Button onClick={handleCancelUpload} intent='primary' autoFocus disabled={loading}>
                {loading ? <WhiteCircularProgress size={24} /> : 'Yes'}
            </Button>
        </DialogActions>
    </Dialog>
}