import React from "react";
import { Card as MCard } from '@material-ui/core';
import { css } from "@emotion/core";
/** @jsx jsx */ 
import { jsx } from '@emotion/core';

const cardCSS = css`
    border-radius: 20px !important;
    background: #fff;
    box-shadow: 0px 3px 8px rgba(62, 100, 146, 0.16);
    padding: 30px 34px;
`;

const cardWrapperCSS = css``;


export function Card (props: any) {

    return <div css={cardWrapperCSS}>
        <MCard css={cardCSS} {...props} />
    </div>;
}