import { css } from "@emotion/core";
import React from "react";
/** @jsx jsx */ 
import { jsx } from '@emotion/core';

const formErrorCSS = css`
    padding-bottom: 16px;
    color: #a42351;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    opacity: .9;
`;

interface FormErrorProps {
    text?: string;
}

export function FormError({ text }: FormErrorProps) {
    return <div css={formErrorCSS}> {text} </div>;
}
