import React, { useContext, useEffect } from "react";
import { globalContext, GlobalContext } from "../store/global";
import { Box, Grid, Paper, Table, TableRow, TableCell, TableHead } from "@material-ui/core";
import { css } from "@emotion/core";
import { useStyles } from "../molecule/file.table.header";
import { LIGHT_GREY_TEXT, MAIN_FONT_FAMILY } from "../styles";
import { FileIcon } from "../atom/file.icon";
import { FormHeader } from "../atom/form.header";

const fileTableCSS = css`
    display: flex;
    justify-content: center;
`;

const tableCSS = css`
    tbody:before {
        content:"@";
        display:block;
        line-height:10px;
        text-indent: -100%;
    }

    font-family: ${MAIN_FONT_FAMILY} !important;
    .MuiTableCell-root {
        font-family: inherit !important;
        font-weight: inherit !important;
    }
    min-width: unset !important;
`;

const headerCSS = css`
    font-size: 24px;
`;

const toolbarCSS = css`
    padding: 10px;
    min-width:1000px;
`;

const rowCSS = css`
    cursor: pointer;
    padding: 10px;
`;

const fileNameCellCSS = css`
    display: flex;
    align-items: center;
    padding: 20px 5px 20px 0;
`;

const headerLineCSS = css`
    background-color: #F1F1F5 !important;
    box-shadow: none !important;
    border-radius: 10px !important;
    font-weight: bold !important;
    color: ${LIGHT_GREY_TEXT} !important;
`;

function openMatter(global: GlobalContext, matterNumber: string) {
    global.actions.setCaseNumber(matterNumber);
    window.location.href = '/';
}

const headerCellCSS = css`
    padding-left: 50px!important;
`;

export function MatterTable() {
    const global = useContext(globalContext);
    const classes = useStyles();
    console.log(global.state);
    return <Box css={fileTableCSS}>
        <Box css={toolbarCSS}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item>
                            <FormHeader css={headerCSS} text={"All available matters"} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        
        <Grid item xs={12} md={10} >
            <Paper className={classes.paper}>
                <Table
                    className={classes.table}
                    aria-labelledby='tableTitle'
                    size='medium'
                    aria-label='Matters Table'
                    css={tableCSS}
                >
                    <MatterTableHead />
                    {global.state.matters.map(matter => <TableRow
                        hover
                        tabIndex={-1}
                        key={matter.matterNumber}
                        css={rowCSS}
                        onClick={() => openMatter(global, matter.matterNumber)}
                    >
                        <TableCell component="th" id={matter.matterNumber} scope="row" padding="checkbox">
                            <div css={fileNameCellCSS}>
                                <FileIcon type={"folder"} />
                                {matter.matterNumber}
                            </div>
                        </TableCell>
                        <TableCell align="left">{}</TableCell>
                        <TableCell align="left">{matter.docketNumber ?? ""}</TableCell>
                        <TableCell align="left">{matter.indictment ?? ""}</TableCell>
                        <TableCell align="left">{matter.defendentsName ?? ""}</TableCell>

                    </TableRow>)}
                </Table>
            </Paper>
        </Grid>
        </Box>
    </Box>;
}

export function MatterTableHead() {
    return <TableHead css={headerLineCSS}>
            <TableRow>
                
                <TableCell align='left' css={headerCellCSS}>
                    Matter #
                </TableCell>

                <TableCell>
                    
                </TableCell>

                <TableCell >
                    Docket #
                </TableCell>

                <TableCell >
                    Indictment #
                </TableCell>

                <TableCell >
                    Defendent Name
                </TableCell>
            </TableRow>
        </TableHead>;
}