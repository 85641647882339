import { css } from "@emotion/core";
/** @jsx jsx */ 
import { jsx } from '@emotion/core';
import React, { PropsWithChildren } from "react";
import { PRIMARY_COLOR } from "../styles";
import { Grid } from "@material-ui/core";

const loginLayout = css`
    min-height: 100vh;
    background: ${PRIMARY_COLOR};
`;

export function LoginLayout(props: PropsWithChildren<{}>) {
    return <Grid container justify="center" alignItems="center" css={loginLayout}>
        <Grid item container direction="column" justify="center" alignItems="center">
            <Grid item xs={12}>
                { props.children }
            </Grid>
        </Grid>
        
    </Grid>
}