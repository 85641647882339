import React from "react";
import { Card } from "../atom/card";
import { css } from "@emotion/core";
/** @jsx jsx */ 
import { jsx } from '@emotion/core';

export interface FormProps {
    children: JSX.Element[] | JSX.Element;
}

const cardCSS = css`
    width: 360px;
    text-align: center;
    border-radius: 20px;
    margin-top: 40px;

    @media screen and (max-width: 360px) {
        width: 100%;
    }

`;

export function FormWrapper({ children }: FormProps) {
    return <Card css={cardCSS}>
            {children}
        </Card>;
}